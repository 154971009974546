import { Badge, IconButton, Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Http from 'services/Http';
import NotificationsIcon from '@material-ui/icons/Notifications';

type StatsType = Array<{ label: string; count: number; }>;

export default function Stats() {

  const [stats, setStats] = useState<StatsType>([]);

  useEffect(() => {
    async function getStats() {
      const { data } = await Http.get<StatsType>('homeworks/stats');
      setStats(data);
    }
    getStats();
  }, []);

  return stats.length ?
    stats.map(({ label, count }) => {
      return (
        <Tooltip
          key={label}
          title={`${label} (${count})`}
        >
          <IconButton color="inherit">
            <Badge badgeContent={count} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      );
    })
    : (
      <IconButton color="inherit" disabled>
        <Badge badgeContent={0} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
    );

};