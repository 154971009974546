import { Navigate as navigate } from 'react-big-calendar';
import { Button, withTheme, Box, Typography } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

// https://github.com/jquense/react-big-calendar/blob/master/stories/props/components.stories.js
// https://github.com/jquense/react-big-calendar/blob/master/stories/props/customComponents/CustomToolbar.component.js
function ViewNamesGroup({ views: viewNames, view, messages, onView, setView }) {
  return viewNames
    .filter((name) => {
      return ['month', 'week'].includes(name);
    })
    .map((name) => {
      return (
        <Button
          disableElevation
          size="small"
          variant="contained"
          color={view === name ? 'secondary' : 'primary'}
          type="button"
          key={name}
          onClick={() => {
            onView(name);
            setView(name);
          }}
        >
          {messages[name]}
        </Button>
      );
    });
}

const CalendarCustomToolbar = withTheme(({
  // date, // available, but not used here
  label,
  localizer: { messages },
  onNavigate,
  onView,
  view,
  views,
  theme,
  history,
  // renderSessionDisciplineFilter,
  renderTrainingFilter,
  renderTeacherFilter,
  isAdmin,
  setView,
  renderFutures,
  renderInPersonSwitch,
}) => {
  return (
    <Box
      style={{
        display: 'flex',
        gap: theme.spacing(1),
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
      }}
    >
      <Box
        style={{
          display: 'flex',
          gap: theme.spacing(1),
          alignItems: 'center',
        }}
      >
        {renderInPersonSwitch()}
        {renderFutures()}
        {renderTrainingFilter()}
        {isAdmin ? renderTeacherFilter() : null}
        {/* {renderSessionDisciplineFilter()} */}
        <ViewNamesGroup
          view={view}
          views={views}
          messages={messages}
          onView={onView}
          setView={setView}
        />
      </Box>
      <Typography variant="h6">{label.toLocaleUpperCase()}</Typography>
      <Box
        style={{
          display: 'flex',
          gap: theme.spacing(1),
        }}
      >
        <Button
          disableElevation
          size="small"
          variant="contained"
          color="primary"
          type="button"
          onClick={() => onNavigate(navigate.PREVIOUS)}
          startIcon={<ArrowLeftIcon />}
        >
          Précédent
        </Button>
        <Button
          disableElevation
          size="small"
          variant="contained"
          color="primary"
          type="button"
          onClick={() => onNavigate(navigate.NEXT)}
          endIcon={<ArrowRightIcon />}
        >
          Suivant
        </Button>
      </Box>
    </Box>
  );
});

export default CalendarCustomToolbar;
