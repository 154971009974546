import { Fragment, useContext, useEffect, useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import frLocale from 'date-fns/locale/fr';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Http from 'services/Http';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import { Backdrop, Box, makeStyles, withTheme } from '@material-ui/core';
import addHours from 'date-fns/addHours';
import getTimezoneOffset from 'date-fns-tz/getTimezoneOffset';
import CalendarCustomToolbar from './CalendarCustomToolbar';
import CalendarCustomEventWrapper from './CalendarCustomEventWrapper';
import CalendarActions from './CalendarActions';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import CalendarCustomDateCellWrapper from './CalendarCustomDateCellWrapper';
import { JwtContext } from 'App';
import DisciplineSessionDialogFilter from './DisciplineSessionDialogFilter';
import TrainingAutocomplete from 'components/Autocompletes/TrainingAutocomplete';
import TeacherAutocomplete from 'components/Autocompletes/TeacherAutocomplete';
import EntryTypeFilter from './EntryTypeFilter';

const messages = {
  week: 'La semaine',
  work_week: 'Semaine de travail',
  day: 'Jour',
  month: 'Mois',
  previous: 'Précédent',
  next: 'Suivant',
  today: `Aujourd'hui`,
  agenda: 'Ordre du jour',
  showMore: (total) => `+${total} plus`,
  noEventsInRange: 'Aucun événement'
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales: { fr: frLocale },
});

export const getResolvedTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getResolvedTimezoneOffset = () => {
  const tz = getResolvedTimezone();
  const tzUser = getTimezoneOffset(tz) / 60 / 60 / 1000;
  const tzParis = getTimezoneOffset('Europe/Paris') / 60 / 60 / 1000;
  return tzUser - tzParis;
};

const useStyles = makeStyles((theme) => {
  return {
    calendar: {
      '& .rbc-event': {
        background: 'none',
        border: 'none',
        padding: '1px 3px',
      },
      '& .rbc-event-label': {
        display: 'none',
      },
    },
  };
});

const CalendarView = (props) => {
  const classes = useStyles();
  const { role } = useContext(JwtContext);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timetable, setTimetable] = useState(null);
  const [action, setAction] = useState(null);
  const [snackbar, setSnackbar] = useState(null);
  const [disciplineSessionJson, setDisciplineSessionJson] = useState(null);
  const [view, setView] = useState('month');

  const searchParams = new URLSearchParams(props.location.search);
  const trainingName = searchParams.get('trainingName');
  const teacher = searchParams.get('teacher');
  const entryType = searchParams.get('entryType');

  const isAdmin = role === 'administrator';
  const isTeacher = role === 'teacher';
  const roles = { isAdmin, isTeacher };

  const dateString = props.match?.params?.date ?? format(new Date(), 'yyyy-MM-dd');

  const onEventClick = (calendarEvent, action) => {
    setTimetable(calendarEvent.data);
    setAction(action);
  };

  const loadTimetable = async (dateString, disciplineSessionJson, trainingName, teacher, entryType) => {
    setLoading(true);
    const { data } = await Http.get('/timetable', {
      params: {
        limit: 200,
        page: 0,
        startDate: format(startOfMonth(new Date(dateString)), 'yyyy-MM-dd'),
        endDate: format(endOfMonth(new Date(dateString)), 'yyyy-MM-dd'),
        disciplineSession: disciplineSessionJson,
        trainingName,
        teacher,
        entryType,
      },
    });
    setEvents(
      data.rows.map((t) => {
        const start = addHours(new Date(t.start_date.replace('Z', '')), getResolvedTimezoneOffset());
        const end = addHours(new Date(t.end_date.replace('Z', '')), getResolvedTimezoneOffset());
        return {
          id: t.id,
          title: '',// `${formatHoursMinutes(t.start_date)}-${formatHoursMinutes(t.end_date)}`,
          start,
          end,
          data: t,
        };
      }),
    );
    setLoading(false);
    return data;
  };

  useEffect(() => {
    loadTimetable(dateString, disciplineSessionJson, trainingName, teacher, entryType);
  }, [dateString, disciplineSessionJson, trainingName, teacher, entryType]);

  return (
    <Fragment>
      <Backdrop
        open={loading}
        style={{ zIndex: 999999, backdropFilter: 'blur(1.5px)' }}
      />
      <Box
        style={{
          height: '100%',
          opacity: loading ? .5 : 1,
        }}
      >
        <ErrorSnackbar
          message={snackbar?.message ?? ''}
          onClose={() => setSnackbar(null)}
          open={snackbar?.type === 'error'}
        />
        <SuccessSnackbar
          message={snackbar?.message ?? ''}
          onClose={() => setSnackbar(null)}
          open={snackbar?.type === 'success'}
        />
        {timetable ? (
          <CalendarActions
            setLoading={setLoading}
            reload={() => {
              return loadTimetable(dateString, disciplineSessionJson, trainingName, teacher, entryType);
            }}
            setSnackbar={setSnackbar}
            timetable={timetable}
            action={action}
            onClose={() => {
              setTimetable(null);
            }}
            onEventClick={onEventClick}
          />
        ) : null}
        <Calendar
          min={new Date(1972, 0, 1, 8, 0, 0, 0)}
          className={classes.calendar}
          culture="fr"
          defaultDate={new Date(dateString)}
          events={events}
          localizer={localizer}
          messages={messages}
          onNavigate={(date) => {
            props.history.push(`/timetable/calendar/${format(date, 'yyyy-MM-dd')}?${searchParams}`);
          }}
          components={{
            event: (eventProps) => {
              return (
                <CalendarCustomEventWrapper
                  {...eventProps}
                  onClick={onEventClick}
                  {...roles}
                />
              );
            },
            dateCellWrapper: (dateCellWrapperProps) => {
              return (
                <CalendarCustomDateCellWrapper
                  view={view}
                  {...dateCellWrapperProps}
                  onClick={onEventClick}
                  events={events}
                  {...roles}
                />
              );
            },
            week: {
              event: (eventProps) => {
                return (
                  <CalendarCustomEventWrapper
                    {...eventProps}
                    onClick={onEventClick}
                    view="week"
                    {...roles}
                  />
                );
              }
            },
            toolbar: (calendarProps) => {
              return (
                <CalendarCustomToolbar
                  setView={setView}
                  {...roles}
                  {...calendarProps}
                  history={props.history}
                  renderFutures={() => null}
                  renderInPersonSwitch={() => {
                    return (
                      <EntryTypeFilter
                        entryType={entryType}
                        filter={(entryType) => {
                          searchParams.set('entryType', entryType);
                          props.history.push(`${props.location.pathname}?${searchParams}`);
                        }}
                      />
                    );
                  }}
                  renderTeacherFilter={() => {
                    return (
                      <TeacherAutocomplete
                        defaultValue={teacher}
                        onChange={(teacher) => {
                          searchParams.delete('teacher');
                          searchParams.append('teacher', teacher);
                          props.history.push(`${props.location.pathname}?${searchParams}`);
                        }}
                      />
                    );
                  }}
                  renderSessionDisciplineFilter={() => {
                    return (
                      <DisciplineSessionDialogFilter
                        state={null}
                        filter={(params) => setDisciplineSessionJson(params.disciplineSession)}
                      />
                    );
                  }}
                  renderTrainingFilter={() => {
                    return (
                      <TrainingAutocomplete
                        defaultValue={trainingName}
                        onChange={(trainingName) => {
                          searchParams.delete('trainingName');
                          searchParams.append('trainingName', trainingName);
                          props.history.push(`${props.location.pathname}?${searchParams}`);
                        }}
                      />
                    );
                  }}
                />
              );
            },
          }}
        />
      </Box>
    </Fragment>
  )
};

export default withTheme(CalendarView);
