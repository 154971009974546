import { format } from 'date-fns';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { Box, IconButton, InputBase, Typography } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SaveIcon from '@material-ui/icons/Save';

import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);
setDefaultLocale('fr');

const FilterDatePicker = ({ defaultValue, filter, filterBy, placeholder }) => {
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const date = formData.get('date');
        if (date) {
          filter({ [filterBy]: date ? format(new Date(date), 'yyyy-MM-dd') : null }, true);
        }
      }}
    >
      <Box position="relative" mb={1}>
        {placeholder ? (
          <Typography
            style={{
              position: 'absolute',
              background: '#fff',
              zIndex: '1',
              top: '0px',
              left: '10px',
              lineHeight: '5px'
            }}
            variant="caption"
          >
            {placeholder}
          </Typography>
        ) : null}
        <Box
          display="flex"
          alignItems="center"
          style={{ gap: '4px' }}
        >
          <InputBase
            style={{
              border: '1px solid rgb(106, 122, 140)',
              fontSize: '12px',
              padding: '2px 4px',
              borderRadius: '4px',
              fontWeight: '500',
            }}
            name="date"
            type="date"
            defaultValue={defaultValue ? defaultValue : undefined}
          />
          <IconButton
            type="submit"
            size="small"
          >
            <SaveIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => filter({ [filterBy]: '' }, true)}
          >
            <HighlightOffIcon />
          </IconButton>
        </Box>
      </Box>
      {/* <ReactDatePicker
        selected={defaultValue ? new Date(defaultValue) : null}
        onChange={(date) => filter({ [filterBy]: date ? format(date, 'yyyy-MM-dd') : null }, true)}
        placeholderText={placeholder}
        dateFormat="dd/MM/yyyy"
        value={defaultValue ? format(new Date(defaultValue), 'dd MMM yyyy', { locale: fr }) : ''}
        customInput={<ButtonDatePicker
          filter={filter}
          filterBy={filterBy}
        />}
      /> */}
    </form>
  );
};

export default FilterDatePicker;
