import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  withStyles,
  Box,
  withTheme,
  Checkbox,
  Button,
  Fade,
  Badge,
  Tooltip,
  alpha,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { tableStyles } from 'styles/datatable.css';
import { styles } from 'components/Timetable/Timetable.css';
import withSortAndPagination from 'components/withSortAndPagination/withSortAndPagination';
import frLocale from 'date-fns/locale/fr';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import format from 'date-fns-tz/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import TeacherDialogAffect from 'components/TeacherDialogAffect/TeacherDialogAffect';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import Http from 'services/Http';
import SchoolIcon from '@material-ui/icons/School';
import WarningIcon from '@material-ui/icons/Warning';
import AddIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import EditDisciplineDialog from './EditDisciplineDialog';
import VideoDialogCreate from 'components/VideoDialogCreate/VideoDialogCreate';
import DisciplineSessionDialog from './DisciplineSessionDialog';
import DateTimeDialog from './DateTimeDialog';
import ZoomDialog from './ZoomDialog';
import ZoomInviteDialog from './ZoomInviteDialog';
import CloneDialog from './CloneDialog';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CustomTeacherDialog from './CustomTeacherDialog';
import { hasAffectedVideos, hasCustomTeacher, hasEveryDisciplineDescription, hasRegisteredTeacher, hasSomeDisciplineDescription, parseDates } from './helpers';
import DescriptionDialog from './DescriptionDialog';
import DisciplinesList from './DisciplinesList';
import CreateOrUpdateDisciplinesButton from './CreateOrUpdateDisciplinesButton';
import CreateOrUpdateZoomButton from './CreateOrUpdateZoomButton';
import CreateOrUpdateReplayButton from './CreateOrUpdateReplayButton';
import VideoPreview from './VideoPreview';
import DisciplineSessionDialogFilter from './DisciplineSessionDialogFilter';
import InPersonSwitch from './InPersonSwitch';
import InPersonConfirmDialog from './InPersonConfirmDialog';
import EntryTypeFilter from './EntryTypeFilter';

const sortableCells = [
  { name: 'id', label: '#' },
];

const sortableAndFilterableCells = [
  {
    cellInfo: { name: 'startDate', label: 'Plage horaire' },
    filtersInfo: [
      { filterBy: 'startDate', placeholder: 'Début', type: 'datePicker' },
      { filterBy: 'endDate', placeholder: 'Fin', type: 'datePicker' },
    ],
  },
  {
    cellInfo: {
      name: 'disciplineSession',
      label: '',
      wrapper: ({ children }) => {
        return (
          <Box style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            {children}
          </Box>
        );
      },
    },
    filtersInfo: [
      {
        filterBy: 'disciplineSession',
        type: 'custom',
        custom: (props) => {
          return (
            <Box style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <DisciplineSessionDialogFilter {...props} />
              <Typography>OU</Typography>
            </Box>
          );
        },
      },
      {
        style: { flex: 1 },
        type: 'autocomplete',
        filterBy: 'trainingName',
        placeholder: 'Formation',
        fetcher: async () => {
          const { data } = await Http.get('/trainings?publishedOnly=false');
          return data.rows.map(({ title }) => title);
        },
      },
    ],
    /*filtersInfo: [{
      type: 'autocomplete',
      multiple: true,
      filterBy: 'disciplineSession',
      placeholder: '',
      fetcher: async (filterState) => {
        const { data } = await Http.get('/timetable/autocomplete');
        return data.map(({ discipline, session }) => {
          // This pattern matters see services/timetable.getAllTimetable
          return `${session.name} ${discipline.name} (#${session.id};${discipline.id})`;
        });
      },
    }],*/
  },
  {
    cellInfo: { name: 'teacher', label: 'Professeur' },
    filtersInfo: [{
      type: 'autocomplete',
      filterBy: 'teacher',
      placeholder: '',
      fetcher: async () => {
        const { data } = await Http.get('/users/teachers/list');
        return data.map(({ last_name, first_name }) => `${last_name} ${first_name}`);
      },
    }],
  },
];

export const formatLongDate = (dateString, dateOnly = false) => {
  return format(
    utcToZonedTime(dateString, 'Etc/UTC'),
    dateOnly ? 'E dd/MM/yyyy' : 'E dd/MM/yyyy HH:mm',
    { locale: frLocale },
  );
};

export const formatLongDateWithoutDay = (dateString, dateOnly = false) => {
  return format(
    utcToZonedTime(dateString, 'Etc/UTC'),
    dateOnly ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm',
    { locale: frLocale },
  );
};

export const formatHoursMinutes = (dateString) => {
  return format(
    utcToZonedTime(dateString, 'Etc/UTC'),
    'HH:mm',
    { locale: frLocale },
  );
};

export const getInPersonColor = (timetable) => {
  return timetable?.in_person === 1
    ? '#850e0e'
    : timetable?.meeting_id
      ? '#0b5cff'
      : '#000';
};

class TimetableAcl extends Component {
  state = {
    loadingOpacity: false,
    snackbar: null,
    openDisciplineDialogAffect: null,
    openTeacherDialogAffect: null,
    selectAll: false,
    selectedIds: [],
    openDateDialog: null,
    openDescriptionDialog: null,
    deleteTimetableDisciplinesConfirmDialog: null,
    editTimetableDisciplineDialog: null,
    createVideoDialog: null,
    viewVideoDialog: null,
    openDisciplineSessionDialogAffect: null,
    openDeleteTimetableDialog: null,
    verbose: true,
    openZoomDialog: null,
    openInviteLinks: null,
    cloneDialog: null,
    openCustomTeacherDialogAffect: null,
    openBulkUpdateTeacherCustomName: null,
    inPersonConfirmDialog: null,
  };

  openSnackbar = (message, type) => {
    this.setState({
      snackbar: {
        message,
        type,
      }
    });
  };

  // affectDiscipline = async ({ /*disciplines, */disciplineIds }) => {
  //   const { timetableIds } = this.state.openDisciplineSessionDialogAffect;
  //   await Http.post('/timetable/createTimetableDisciplines', {
  //     timetableIds,
  //     disciplineIds,
  //   });
  //   this.props.reload();
  // }

  /**
   * @param {Array<{
   *  discipline_id: number;
   *  sessionName: string;
   *  disciplineName: string;
   *  session_id: number;
   * }>} disciplineSessionIds
   */
  affectDisciplineSession = async (disciplineSessionIds) => {
    const { timetableIds } = this.state.openDisciplineSessionDialogAffect;
    await Http.post('/timetable/createTimetableDisciplines', {
      timetableIds,
      disciplineSessionIds: disciplineSessionIds,
    });
    this.props.reload();
  };

  affectTeacher = async ({ teacher_id }) => {
    const { timetableIds } = this.state.openTeacherDialogAffect;
    await Http.post('/timetable/bulkUpdateTimetableTeacher', {
      teacherId: teacher_id,
      timetableIds,
    });
    this.props.reload();
    this.openSnackbar('Professeur affecté !', 'success');
    this.setState({ openTeacherDialogAffect: null, selectedIds: [] });
  }

  closeTeacherDialog = () => {
    this.setState({ openTeacherDialogAffect: null });
  }

  onSelectAllClick = ({ target }) => {
    const { checked } = target;
    const selectedIds = checked ? this.props.rows.map(({ id }) => String(id)) : [];
    this.setState({ selectAll: checked, selectedIds });
  }

  onSelectOne = ({ target }) => {
    const { checked, value } = target;
    const selectedIds = [...this.state.selectedIds];
    if (checked) {
      selectedIds.push(value);
    } else {
      const index = selectedIds.indexOf(value);
      if (index !== -1) {
        selectedIds.splice(index, 1);
      }
    }
    this.setState({ selectedIds });
  }

  saveDate = async () => {
    const { openDateDialog } = this.state;
    if (openDateDialog.id) {
      await Http.put(`/timetable/${openDateDialog.id}`, this.state.openDateDialog);
    } else {
      await Http.post(`/timetable`, this.state.openDateDialog);
    }
    this.setState({ openDateDialog: null });
    this.props.reload();
    this.openSnackbar('Plage horaire enregistrée !', 'success');
  };

  parseDates = () => {
    const { openDateDialog } = this.state;
    const dates = {
      startDate: {
        dateString: undefined,
        hourMinutes: undefined,
      },
      endDate: {
        dateString: undefined,
        hourMinutes: undefined,
      },
    };
    if (openDateDialog) {
      dates.startDate = {
        dateString: openDateDialog?.startDate ? openDateDialog?.startDate.split(' ').at(0) : undefined,
        hourMinutes: openDateDialog?.startDate ? openDateDialog?.startDate.split(' ').at(1)?.substring(0, 5) : undefined,
      };
      dates.endDate = {
        dateString: openDateDialog?.endDate ? openDateDialog?.endDate.split(' ').at(0) : dates.startDate?.dateString,
        hourMinutes: openDateDialog?.endDate ? openDateDialog?.endDate.split(' ').at(1)?.substring(0, 5) : dates.startDate?.hourMinutes,
      };
    }
    return dates;
  };

  openBulkUpdateTeacherCustomNameDialog = (timetableIds) => {
    return (event) => {
      this.setState({
        openBulkUpdateTeacherCustomName: {
          timetableIds,
        },
      });
    };
  };

  openCustomTeacherDialog = (timetable) => {
    return (event) => {
      this.setState({
        openCustomTeacherDialogAffect: {
          timetable,
        },
      });
    };
  }

  openTeacherDialog = (timetableIds) => {
    return (event) => {
      this.setState({
        openTeacherDialogAffect: {
          timetableIds,
        },
      });
    };
  };

  openStartDateDialogHandler = (timetable) => {
    return (event) => {
      this.setState({
        openDateDialog: {
          id: timetable?.id,
          startDate: timetable?.start_date?.replace('T', ' '),
          endDate: timetable?.end_date?.replace('T', ' '),
        },
      });
    };
  };

  closeDateDialogHandler = () => {
    this.setState({ openDateDialog: null });
  };

  saveDescription = async (id, html) => {
    this.closeDescriptionDialog();
    await Http.put(`/timetable/${id}`, {
      description: html,
    });
    this.props.reload();
    this.openSnackbar('Description enregistrée !', 'success');
  };

  closeDescriptionDialog = () => {
    this.setState({ openDescriptionDialog: null });
  };

  deleteTimetableDisciplines = async () => {
    const { id } = this.state.deleteTimetableDisciplinesConfirmDialog;
    await Http.delete(`/timetable/deleteOneTimetableDiscipline/${id}`);
    this.closeDeleteTimetableDisciplinesConfirmDialog();
    this.props.reload();
    this.openSnackbar('Matière supprimée !', 'success');
    this.setState({ deleteTimetableDisciplinesConfirmDialog: null, selectedIds: [] });
  };

  closeDeleteTimetableDisciplinesConfirmDialog = () => {
    this.setState({ deleteTimetableDisciplinesConfirmDialog: null });
  };

  updateTimetableDisciplineCustomName = async (data) => {
    const { editTimetableDisciplineDialog } = this.state;
    this.setState({ editTimetableDisciplineDialog: null });
    await Http.put('/timetable/updateTimetableDisciplineCustomName', {
      ...data,
      disciplineId: data.id,
      timetableId: editTimetableDisciplineDialog.timetableId,
    });
    this.props.reload();
    this.openSnackbar('Matière mise à jour !', 'success');
  };

  closeCreateVideoDialog = () => {
    this.setState({ createVideoDialog: null });
  };

  onCreateVideo = async ({ bulkCreateVideoData }) => {
    const { createVideoDialog } = this.state;
    if (bulkCreateVideoData.length !== 1) {
      return this.openSnackbar(`Merci de ne pas sélectionner plus d'une vidéo`, 'error');
    }
    await Http.post('/timetable/updateTimetableDisciplineVideo', {
      video: bulkCreateVideoData.at(0),
      timetableId: createVideoDialog.timetableId,
      category: 'replay'
    });
    this.openSnackbar('Vidéo affectée !', 'success');
    this.closeCreateVideoDialog();
    this.props.reload();
  };

  openDeleteTimetableDialog = (timetableIds) => {
    return (event) => {
      this.setState({
        openDeleteTimetableDialog: {
          timetableIds,
        },
      });
    };
  };

  closeDeleteTimetableConfirmDialog = () => {
    this.setState({ openDeleteTimetableDialog: null });
  };

  deleteTimetable = async () => {
    const { timetableIds } = this.state.openDeleteTimetableDialog;
    await Http.delete('/timetable', {
      data: timetableIds,
    });
    this.setState({ openDeleteTimetableDialog: null, selectedIds: [] });
    this.props.reload();
    this.openSnackbar('Suppression effectuée !', 'success');
  };

  cloneTimetable = async (params) => {
    // await Http.post('/timetable/cloneTimetable', {
    //   ...params,
    //   timetableId: params.timetableIds.at(0),
    // });
    await Http.post('/timetable/cloneTimetableDates', {
      ...params,
      timetableId: params.timetableIds.at(0),
    });
    this.setState({ cloneDialog: null, selectedIds: [] });
    this.props.reload();
    this.openSnackbar('Clonage effectuée !', 'success');
  }

  openCloneDialog = (timetableIds) => {
    this.setState({
      cloneDialog: {
        timetableIds,
      },
    });
  }

  render() {
    const {
      classes,
      loading,
      renderPagination,
      renderSortCell,
      renderSortableAndFilterable,
      rows,
      theme,
      filter,
      filterState,
      role,
    } = this.props;

    const {
      loadingOpacity,
      snackbar,
      openTeacherDialogAffect,
      selectAll,
      selectedIds,
      openDateDialog,
      openDescriptionDialog,
      deleteTimetableDisciplinesConfirmDialog,
      editTimetableDisciplineDialog,
      createVideoDialog,
      viewVideoDialog,
      openDisciplineSessionDialogAffect,
      openDeleteTimetableDialog,
      verbose,
      openZoomDialog,
      openInviteLinks,
      cloneDialog,
      openCustomTeacherDialogAffect,
      openBulkUpdateTeacherCustomName,
      inPersonConfirmDialog,
    } = this.state;
    const loadingStyle = { opacity: (loading || loadingOpacity) ? .5 : 1 };

    const isAdmin = role === 'administrator';
    const isTeacher = role === 'teacher';

    return (
      <Paper
        elevation={1}
        className={classes.root}
        style={{
          ...loadingStyle,
          // ...(calendarView ? {
          //   flexDirection: 'column',
          //   display: 'flex',
          //   alignItems: 'stretch',
          //   minHeight: '100vh',
          // } : null),
        }}
      >
        {openBulkUpdateTeacherCustomName ? (
          <CustomTeacherDialog
            open={openBulkUpdateTeacherCustomName !== null}
            onClose={() => {
              this.setState({ openBulkUpdateTeacherCustomName: null });
            }}
            timetable={undefined}
            onSubmit={async ({ teacherCustomName }) => {
              await Http.post('/timetable/bulkUpdateTeacherCustomName', {
                teacherCustomName,
                timetableIds: openBulkUpdateTeacherCustomName.timetableIds,
              });
              this.props.reload();
              this.openSnackbar('Professeurs mis à jour !', 'success');
              this.setState({ openBulkUpdateTeacherCustomName: null, selectedIds: [] });
            }}
          />
        ) : null}
        {openCustomTeacherDialogAffect ? (
          <CustomTeacherDialog
            open={openCustomTeacherDialogAffect !== null}
            onClose={() => {
              this.setState({ openCustomTeacherDialogAffect: null });
            }}
            timetable={openCustomTeacherDialogAffect.timetable}
            onSubmit={async ({ teacherCustomName, id }) => {
              await Http.post('/timetable', { teacherCustomName, id });
              this.props.reload();
              this.openSnackbar('Professeur mis à jour !', 'success');
              this.setState({ openCustomTeacherDialogAffect: null });
            }}
          />
        ) : null}
        {openInviteLinks ? (
          <ZoomInviteDialog
            meetingId={openInviteLinks.meetingId}
            onClose={() => this.setState({ openInviteLinks: null })}
            openSnackbar={this.openSnackbar}
          />
        ) : null}
        {openZoomDialog ? (
          <ZoomDialog
            open
            defaultTopic={openZoomDialog.defaultTopic}
            onClose={() => {
              this.setState({ openZoomDialog: null });
            }}
            zoomUser={openZoomDialog.zoomUser}
            onSave={async ({ zoomUser, topic }) => {
              try {
                await Http.post('/timetable/zoomCreateMeeting', {
                  timetableId: openZoomDialog.timetableId,
                  zoomUser,
                  topic,
                });
                this.openSnackbar('Réunion enregistrée !', 'success');
              } catch (e) {
                this.openSnackbar(e.data.message ?? 'Erreur technique', 'error');
              } finally {
                this.props.reload();
                this.setState({ openZoomDialog: null });
              }
            }}
            meetingId={openZoomDialog?.meetingId}
          />
        ) : null}
        {viewVideoDialog ? (
          <VideoPreview
            embed={viewVideoDialog?.embed}
            onClose={() => {
              this.setState({ viewVideoDialog: null });
            }}
          />
        ) : null}
        <CloneDialog
          open={cloneDialog !== null}
          timetableIds={cloneDialog?.timetableIds}
          onClose={() => this.setState({ cloneDialog: null })}
          onSave={(params) => this.cloneTimetable(params)}
          onError={console.log}
        />
        <InPersonConfirmDialog
          timetable={inPersonConfirmDialog?.timetable}
          checked={inPersonConfirmDialog?.checked}
          onClose={() => {
            this.setState({ inPersonConfirmDialog: null });
          }}
          onConfirm={() => {
            this.setState({ inPersonConfirmDialog: null });
            this.props.reload();
            this.openSnackbar('Evénement mis à jour', 'success');
          }}
        />
        <ConfirmationDialog
          open={deleteTimetableDisciplinesConfirmDialog !== null}
          title="Attention"
          message={`Êtes-vous sûr de vouloir supprimer cette matière ?`}
          onClose={this.closeDeleteTimetableDisciplinesConfirmDialog}
          onConfirm={this.deleteTimetableDisciplines}
        />
        <ConfirmationDialog
          open={openDeleteTimetableDialog !== null}
          title="Attention"
          message={`Êtes-vous sûr de vouloir supprimer cette entrée de l'emploi du temps ?`}
          onClose={this.closeDeleteTimetableConfirmDialog}
          onConfirm={this.deleteTimetable}
        />
        <EditDisciplineDialog
          role={role}
          description={editTimetableDisciplineDialog?.description}
          open={editTimetableDisciplineDialog !== null}
          discipline={editTimetableDisciplineDialog?.discipline}
          discipline_custom_name={editTimetableDisciplineDialog?.discipline_custom_name}
          onClose={() => {
            this.setState({ editTimetableDisciplineDialog: null });
          }}
          onSubmit={this.updateTimetableDisciplineCustomName}
        />
        <ErrorSnackbar
          message={snackbar?.message ?? ''}
          onClose={() => this.setState({ snackbar: null })}
          open={snackbar?.type === 'error'}
        />
        <SuccessSnackbar
          message={snackbar?.message ?? ''}
          onClose={() => this.setState({ snackbar: null })}
          open={snackbar?.type === 'success'}
        />
        <DateTimeDialog
          open={openDateDialog !== null}
          title="Créer une entrée"
          dateString={parseDates(openDateDialog).startDate.dateString}
          hourMinutesStart={parseDates(openDateDialog).startDate.hourMinutes}
          hourMinutesEnd={parseDates(openDateDialog).endDate.hourMinutes}
          onClose={this.closeDateDialogHandler}
          onConfirm={(startDate, endDate) => {
            this.setState({
              openDateDialog: {
                ...this.state.openDateDialog,
                endDate,
                startDate,
              },
            });
            setTimeout(() => {
              // wait for next tick to get fresh state
              this.saveDate();
            }, 0);
          }}
        />
        {openDisciplineSessionDialogAffect !== null ? (
          <DisciplineSessionDialog
            open
            onClose={() => {
              this.setState({ openDisciplineSessionDialogAffect: null });
            }}
            onSubmit={this.affectDisciplineSession}
            initialCart={openDisciplineSessionDialogAffect?.selectedDisciplineIds ?? []}
          />
        ) : null}
        <TeacherDialogAffect
          basicMode
          open={openTeacherDialogAffect !== null}
          onClose={this.closeTeacherDialog}
          onAffect={this.affectTeacher}
        />
        <VideoDialogCreate
          open={createVideoDialog !== null}
          onClose={this.closeCreateVideoDialog}
          onCreate={this.onCreateVideo}
          disableEndpoint
        />
        {openDescriptionDialog !== null ? (
          <DescriptionDialog
            onClose={this.closeDescriptionDialog}
            defaultValue={openDescriptionDialog?.html ?? ''}
            onSave={(html) => {
              this.saveDescription(openDescriptionDialog?.id, html);
            }}
          />
        ) : null}
        <Toolbar>
          {isAdmin ? (
            <Fragment>
              <FormControlLabel
                label={!verbose
                  ? 'Masquer le détail session matière'
                  : (<Typography style={{ textDecoration: 'line-through', opacity: .5 }}>Masquer le détail session matière</Typography>)
                }
                control={
                  <Switch
                    checked={!verbose}
                    onChange={() => {
                      this.setState((state) => {
                        return {
                          verbose: !state.verbose,
                        };
                      });
                    }}
                  />
                }
              />
              <FormControlLabel
                label={filterState?.zoomWithoutReplay !== '1'
                  ? (<Typography style={{ textDecoration: 'line-through', opacity: .5 }}>Vidéo à affecter</Typography>)
                  : 'Vidéo à affecter'
                }
                control={
                  <Switch
                    checked={filterState?.zoomWithoutReplay === '1'}
                    onChange={() => {
                      filter({ zoomWithoutReplay: filterState?.zoomWithoutReplay === '1' ? '0' : '1' }, true);
                    }}
                  />
                }
              />
              <EntryTypeFilter
                entryType={filterState?.entryType}
                filter={(entryType) => {
                  filter({ entryType }, true);
                }}
              />
            </Fragment>
          ) : null}
          <Typography className={classes.title} variant="h6" color="inherit" />
          {isAdmin ? (
            <Fragment>
              <Box
                display="flex"
                style={{
                  gap: theme.spacing(1),
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={this.openStartDateDialogHandler()}
                >
                  Créer une entrée
                </Button>
              </Box>
            </Fragment>
          ) : null}
        </Toolbar>
        {isAdmin ? (
          <Fade in={selectedIds.length > 0} unmountOnExit>
            <Box
              display="flex"
              style={{
                gap: theme.spacing(1),
              }}
              px={3}
              justifyContent="flex-end"
            >
              <Tooltip title="Cloner la ligne sélectionnée : date et matière(s)">
                <span>
                  <Button
                    disabled={selectedIds.length !== 1}
                    className={classes.buttonBadge}
                    size="small"
                    variant="contained"
                    disableElevation
                    onClick={() => this.openCloneDialog(selectedIds)}
                  >
                    Cloner
                  </Button>
                </span>
              </Tooltip>
              <Badge
                color="secondary"
                badgeContent={selectedIds.length}
                style={{ display: selectedIds.length > 0 ? 'block' : 'none' }}>
                <Button
                  className={classes.buttonBadge}
                  size="small"
                  variant="contained"
                  disableElevation
                  onClick={this.openDeleteTimetableDialog(selectedIds)}
                >
                  Supprimer
                </Button>
              </Badge>
              <Badge
                color="primary"
                badgeContent={selectedIds.length}
                style={{ display: selectedIds.length > 0 ? 'block' : 'none' }}>
                <Button
                  className={classes.buttonBadge}
                  size="small"
                  variant="contained"
                  disableElevation
                  onClick={this.openTeacherDialog(selectedIds)}
                >
                  Affecter au professeur
                </Button>
              </Badge>
              <Badge
                color="primary"
                badgeContent={selectedIds.length}
                style={{ display: selectedIds.length > 0 ? 'block' : 'none' }}>
                <Button
                  className={classes.buttonBadge}
                  size="small"
                  variant="contained"
                  disableElevation
                  onClick={this.openBulkUpdateTeacherCustomNameDialog(selectedIds)}
                >
                  Nom personnalisé (prof)
                </Button>
              </Badge>
              <Tooltip title="⚠️ Lors d'une affectation par lot, veuillez noter que les matières existantes seront écrasées par votre nouvelle sélection de matières.">
                <Badge
                  color="primary"
                  badgeContent={selectedIds.length}
                  style={{ display: selectedIds.length > 0 ? 'block' : 'none' }}>
                  <Button
                    className={classes.buttonBadge}
                    size="small"
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      this.setState({
                        openDisciplineSessionDialogAffect: {
                          timetableIds: selectedIds,
                        },
                      });
                    }}
                  >
                    {`Affecter ${selectedIds.length > 1 ? 'aux' : 'à la'} matière${selectedIds.length > 1 ? 's' : ''}`}
                  </Button>
                </Badge>
              </Tooltip>
            </Box>
          </Fade>
        ) : null}

        <Table size="small" className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {isAdmin ? (
                <Fragment>
                  <TableCell align="left">
                    <Checkbox
                      onChange={this.onSelectAllClick}
                      checked={selectAll}
                    />
                  </TableCell>
                  {sortableCells.map(cell => renderSortCell(cell))}
                </Fragment>
              ) : null}
              {sortableAndFilterableCells
                .filter(({ cellInfo, filtersInfo }) => {
                  if (isAdmin) {
                    return true;
                  }
                  return cellInfo.name !== 'teacher';
                })
                .map(({ cellInfo, filtersInfo }) => renderSortableAndFilterable(cellInfo, filtersInfo))}
              <TableCell>Détails</TableCell>
              <TableCell>Zoom</TableCell>
              {isAdmin ? (<TableCell>Replay</TableCell>) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((timetable) => {
              const { timetableDisciplines } = timetable;

              return (
                <TableRow
                  key={timetable.id}
                >
                  {isAdmin ? (
                    <Fragment>
                      <TableCell>
                        <Checkbox
                          value={String(timetable.id)}
                          onChange={this.onSelectOne}
                          checked={selectedIds.includes(String(timetable.id))}
                        />
                      </TableCell>
                      <TableCell>{timetable.id}</TableCell>
                    </Fragment>
                  ) : null}
                  <TableCell
                    align="center"
                    style={{
                      background: alpha(getInPersonColor(timetable), .25),
                      padding: theme.spacing(1),
                    }}
                  >
                    <Button
                      variant="contained"
                      disableElevation
                      color="primary"
                      endIcon={<CalendarIcon style={{ fontSize: '30px' }} />}
                      onClick={isTeacher
                        ? () => { }
                        : this.openStartDateDialogHandler(timetable)
                      }
                      disabled={isTeacher}
                    >
                      <Box display="flex" flexDirection="column">
                        <Typography variant="caption">
                          {`${formatLongDate(timetable.start_date, true)}`.toLocaleUpperCase()}
                        </Typography>
                        <Typography
                          variant="caption"
                          dangerouslySetInnerHTML={{
                            __html: `de <strong>${formatHoursMinutes(timetable.start_date)}</strong> à <strong>${formatHoursMinutes(timetable.end_date)}</strong>`
                          }}
                        >
                        </Typography>
                      </Box>
                    </Button>
                  </TableCell>
                  <TableCell style={{ background: timetableDisciplines.length > 0 ? alpha(theme.palette.success.main, .25) : alpha(theme.palette.error.main, .25), padding: theme.spacing(1) }}>
                    <Box
                      display="flex"
                      style={{ gap: theme.spacing(1) }}
                      alignItems={timetableDisciplines.length > 0 ? 'center' : 'center'}
                      justifyContent="center"
                    >
                      {isAdmin ? (
                        <Box
                          display="flex"
                          style={{ gap: theme.spacing(1) }}
                          flexDirection="column"
                          alignItems="center"
                        >
                          {timetableDisciplines.length > 0 ? (
                            hasSomeDisciplineDescription(timetable)
                              ? hasEveryDisciplineDescription(timetable)
                                ? (
                                  <Tooltip title="Cette entrée a une description par matière. Elle est donc considérée comme une entrée physique (pas de Zoom)">
                                    <ThumbUpIcon />
                                  </Tooltip>
                                )
                                : (
                                  <Tooltip title="Cette entrée a une description de matière, mais pas pour toutes les matières. Merci de renseigner les matières manquantes">
                                    <WarningIcon />
                                  </Tooltip>
                                )
                              : null
                          ) : null}
                          <CreateOrUpdateDisciplinesButton
                            onClick={(params) => {
                              this.setState({
                                openDisciplineSessionDialogAffect: params,
                              });
                            }}
                            timetable={timetable}
                            verbose={verbose}
                          />
                        </Box>
                      ) : null}
                      {verbose ? (
                        <DisciplinesList
                          role={role}
                          onDeleteDiscipline={(id) => {
                            this.setState({
                              deleteTimetableDisciplinesConfirmDialog: { id },
                            });
                          }}
                          onEditDisciplines={(params) => {
                            this.setState({
                              editTimetableDisciplineDialog: params,
                            });
                          }}
                          timetable={timetable}
                        />
                      ) : null}
                    </Box>
                  </TableCell>
                  {isAdmin ? (
                    <TableCell align="center" style={{ background: (hasRegisteredTeacher(timetable) || hasCustomTeacher(timetable)) ? alpha(theme.palette.success.main, .25) : alpha(theme.palette.error.main, .25), padding: theme.spacing(1) }}>
                      <Box
                        style={{
                          display: 'flex',
                          gap: theme.spacing(1),
                          flexDirection: 'column',
                        }}
                      >
                        <Button
                          size="small"
                          variant="contained"
                          disableElevation
                          color={hasRegisteredTeacher(timetable) ? 'primary' : 'secondary'}
                          endIcon={<SchoolIcon />}
                          startIcon={hasRegisteredTeacher(timetable) ? null : <WarningIcon />}
                          onClick={this.openTeacherDialog([timetable.id])}
                        >
                          {
                            timetable?.teacher
                              ? `${timetable?.teacher?.last_name} ${timetable?.teacher?.first_name}`
                              : 'Affecter'
                          }
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          disableElevation
                          color={hasCustomTeacher(timetable) ? 'primary' : 'secondary'}
                          endIcon={<PersonAddIcon />}
                          startIcon={hasCustomTeacher(timetable) ? null : <WarningIcon />}
                          onClick={this.openCustomTeacherDialog(timetable)}
                        >
                          {hasCustomTeacher(timetable)
                            ? timetable?.teacher_custom_name
                            : 'Nom personnalisé'}
                        </Button>
                      </Box>
                    </TableCell>
                  ) : null}
                  <TableCell align="center">
                    <Button
                      size="small"
                      variant="contained"
                      disableElevation
                      color="primary"
                      endIcon={timetable.description ? <EditIcon /> : <AddIcon />}
                      startIcon={timetable.description
                        ? (
                          <Tooltip
                            title={
                              <Box dangerouslySetInnerHTML={{ __html: timetable.description ?? '' }} />
                            }
                          >
                            <VisibilityIcon />
                          </Tooltip>
                        )
                        : null}
                      onClick={() => {
                        this.setState({
                          openDescriptionDialog: {
                            html: timetable.description ?? '',
                            id: timetable.id,
                          }
                        });
                      }}
                    >
                      {timetable.description ? 'Éditer' : 'Ajouter'}
                    </Button>
                  </TableCell>
                  <TableCell align="center" style={{ background: timetable.meeting_id ? alpha(theme.palette.success.main, .25) : alpha(theme.palette.error.main, .25), padding: theme.spacing(1) }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      style={{ gap: theme.spacing(1) }}
                      flexDirection="column"
                    >
                      <CreateOrUpdateZoomButton
                        timetable={timetable}
                        onClick={(params) => {
                          this.setState({
                            openZoomDialog: params,
                          });
                        }}
                        onInviteClick={(params) => {
                          this.setState({
                            openInviteLinks: params,
                          });
                        }}
                      />
                      <InPersonSwitch
                        timetable={timetable}
                        onChange={({ checked, timetable }) => {
                          this.setState({
                            inPersonConfirmDialog: {
                              checked,
                              timetable,
                            },
                          });
                        }}
                      />
                    </Box>
                  </TableCell>
                  {isAdmin ? (
                    <TableCell align="center" style={{ background: hasAffectedVideos(timetable) ? alpha(theme.palette.success.main, .25) : alpha(theme.palette.error.main, .25), padding: theme.spacing(1) }}>
                      <CreateOrUpdateReplayButton
                        timetable={timetable}
                        onClick={(params) => {
                          this.setState({
                            createVideoDialog: params,
                          });
                        }}
                        onVideoDialogClick={(params) => {
                          this.setState({
                            viewVideoDialog: params,
                          });
                        }}
                        openSnackbar={this.openSnackbar}
                      />
                    </TableCell>
                  ) : null}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {renderPagination()}
      </Paper>
    );
  }
}

TimetableAcl.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(withStyles((theme) => ({
  ...tableStyles(theme),
  ...styles(theme),
}))(
  withSortAndPagination(TimetableAcl, 'timetable', 'startDate', 'asc')
));
