import { Divider, Typography, useTheme } from '@material-ui/core';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialogTs';
import Http from 'services/Http';

type Props = {
  timetable?: any;
  checked?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onError: (reason: string) => void;
};

export default function InPersonConfirmDialog({
  timetable,
  checked,
  onClose,
  onConfirm,
  onError,
}: Props) {
  const theme = useTheme();
  return (
    <ConfirmationDialog
      open={timetable !== undefined}
      title="Attention"
      message={
        checked ? (
          <Typography>
            Vous êtes sur le point de marquer cet événement en tant que "présentiel".<br />
            {timetable?.meeting_id ? (
              <strong>⚠️ Une réunion Zoom a été préalablement créée, elle sera supprimée<br /></strong>
            ) : null}
            <Divider style={{ margin: theme.spacing(1) }} />
            Cliquez sur <strong>OUI</strong> pour confirmer, <strong>NON</strong> pour annuler
          </Typography>
        ) : (
          <Typography>
            Vous êtes sur le point de marquer explicitement cet événement en tant que "non présentiel".<br />
            💡 Pensez à affecter une réunion Zoom, ou à supprimer cet événement !<br />
            <Divider style={{ margin: theme.spacing(1) }} />
            Cliquez sur <strong>OUI</strong> pour confirmer, <strong>NON</strong> pour annuler
          </Typography>
        )
      }
      onClose={() => {
        onClose();
      }}
      onConfirm={async () => {
        if (!timetable || typeof checked === 'undefined') {
          onError('Missing timetable or checked');
        }
        await Http.post('/timetable/setInPerson', { timetable, checked });
        onConfirm();
      }}
    />
  );
};
