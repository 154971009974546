import {
  AppBar,
  Avatar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AttachmentIcon from '@material-ui/icons/Attachment';
import BallotIcon from '@material-ui/icons/Ballot';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DomainIcon from '@material-ui/icons/Domain';
import DvrIcon from '@material-ui/icons/Dvr';
import EmailIcon from '@material-ui/icons/Email';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import EuroIcon from '@material-ui/icons/Euro';
import EventIcon from '@material-ui/icons/Event';
import FaceIcon from '@material-ui/icons/Face';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import HomeIcon from '@material-ui/icons/Home';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockIcon from '@material-ui/icons/Lock';
import MenuIcon from '@material-ui/icons/Menu';
import MessageIcon from '@material-ui/icons/Message';
import MicIcon from '@material-ui/icons/Mic';
import MovieIcon from '@material-ui/icons/Movie';
import PaymentIcon from '@material-ui/icons/Payment';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import SchoolIcon from '@material-ui/icons/School';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Alert } from '@material-ui/lab';
import classNames from 'classnames';
import Documents from 'components/Documents/Documents';
import Home from 'components/Home/Home';
import Homeworks from 'components/Homeworks/Homeworks';
import Logs from 'components/Logs/Logs';
import News from 'components/News/News';
import Orders from 'components/Orders/Orders';
import Payzen from 'components/Payzen/Payzen';
import Podcasts from 'components/Podcasts/Podcasts';
import Polycopies from 'components/Polycopies/Polycopies';
import Proofreading from 'components/Proofreading/Proofreading';
import Rattrapages from 'components/Rattrapages/Rattrapages';
import Reglements from 'components/Reglements/Reglements';
import Search, { searchTypes } from 'components/Search/Search';
import SessionDetails from 'components/SessionDetails/SessionDetails';
import Sessions from 'components/Sessions/Sessions';
import Settings from 'components/Settings/Settings';
import SidebarMenu from 'components/SidebarMenu/SidebarMenu';
import SidebarUser from 'components/SidebarUser/SidebarUser';
import SignIn from 'components/SignIn/SignIn';
import Statistics from 'components/Statistics/Statistics';
import StudentDetails, { studentTabs } from 'components/StudentDetails/StudentDetails';
import Students from 'components/Students/Students';
import TeacherDetails from 'components/TeacherDetails/TeacherDetails';
import Teachers from 'components/Teachers/Teachers';
import CalendarView from 'components/Timetable/CalendarView';
import Timetable from 'components/Timetable/Timetable';
import TopicDetails from 'components/TopicDetails/TopicDetails';
import Topics from 'components/Topics/Topics';
import Trainings from 'components/Trainings/Trainings';
import UserBookmarks from 'components/UserBookmarks/UserBookmarks';
import Videos from 'components/Videos/Videos';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, Redirect, Route, withRouter } from 'react-router-dom';
import { clearAccessToken } from 'services/AuthService';
import Can from 'shared/Can/Can';
import Forbidden from 'shared/Can/Forbidden';
import { Manager } from 'socket.io-client';
import { JwtContext } from '../../App';
import { styles } from './Dashboard.css';
import Stats from './Stats';

export const superUsersLogin = ['root'];
export const isSuperUser = (login) => superUsersLogin.includes(login);

class SocketSync extends Component {
  state = {
    socketData: {},
    syncing: true,
  };

  setSocketState = (key, mapper = null, cb = null) => {
    return (data) => {
      // console.log('setSocketState', key, data);
      if (cb) {
        cb();
      }
      this.setState((state) => {
        return {
          socketData: {
            ...state.socketData,
            [key]: mapper ? mapper(data) : data,
          }
        };
      });
    };
  };

  async componentDidMount() {
    const manager = new Manager(process.env.REACT_APP_WS_URL);
    const socket = manager.socket('/'); // main namespace
    socket.io.on('error', (error) => {
      console.log('socket error', error.message);
      socket.disconnect();
    });
    socket.on('cron:startCycle', this.setSocketState(
      'cron:startCycle',
      ({ date }) => date),
    );
    socket.on('cron:endCycle', this.setSocketState(
      'cron:endCycle',
      ({ date }) => date,
      () => {
        this.setState({ syncing: false });
        setTimeout(() => {
          this.setState({ syncing: true });
        }, 4000);
      },
    ));
  }

  render() {
    const { syncing, socketData } = this.state;
    return (
      <Slide in={!syncing} unmountOnExit mountOnEnter direction="down">
        <Alert variant="filled" severity="success">{`Dernière synchro : ${socketData['cron:endCycle']}`}</Alert>
      </Slide>
    );
  }
}

const Title = ({ classes, pathname }) => {
  let data = { title: '', icon: null };
  switch (true) {
    case pathname === '/timetable': data = { title: 'Emploi du temps', icon: EventIcon }; break;
    case pathname === '/payzen': data = { title: 'Paiements refusés', icon: PaymentIcon }; break;
    case pathname === '/logs': data = { title: 'Logs', icon: DvrIcon }; break;
    case pathname === '/documents': data = { title: 'Documents', icon: AttachmentIcon }; break;
    case pathname === '/homeworks': data = { title: 'Copies', icon: ListAltIcon }; break;
    case pathname === '/news': data = { title: 'Actualités', icon: MessageIcon }; break;
    case pathname === '/orders': data = { title: 'Commandes', icon: ShoppingCartIcon }; break;
    case pathname === '/podcasts': data = { title: 'Podcasts', icon: MicIcon }; break;
    case pathname === '/polycopies': data = { title: 'Polycopiés', icon: EmailIcon }; break;
    case pathname === '/rattrapages': data = { title: 'Rattrapages', icon: MovieIcon }; break;
    case pathname === '/reglements': data = { title: 'Règlements', icon: EuroIcon }; break;
    case pathname === '/sessions': data = { title: 'Sessions', icon: DomainIcon }; break;
    case pathname === '/settings': data = { title: 'Paramètres', icon: SettingsIcon }; break;
    case pathname === '/statistics': data = { title: 'Statistiques', icon: EqualizerIcon }; break;
    case pathname === '/students': data = { title: 'Étudiants', icon: FaceIcon }; break;
    case pathname === '/teachers': data = { title: 'Professeurs', icon: SchoolIcon }; break;
    case pathname === '/proofreading': data = { title: 'Paiements corrigés', icon: BallotIcon }; break;
    case pathname === '/topics': data = { title: 'Sujets', icon: ListAltIcon }; break;
    case pathname === '/trainings': data = { title: 'Formations', icon: BookmarkIcon }; break;
    case pathname === '/videos': data = { title: 'Vidéos', icon: MovieIcon }; break;
    case pathname === '/search': data = { title: searchTypes[Object.keys(searchTypes)[0]], icon: QueryBuilderIcon }; break;
    case pathname.indexOf('/search/') >= 0:
      const key = pathname.split('/').pop().trim();
      data = { title: searchTypes[key] ?? searchTypes[Object.keys(searchTypes)[0]], icon: QueryBuilderIcon };
      break;
    case pathname.indexOf('/sessions/') >= 0:
      data = { title: 'Détail de la session', icon: DomainIcon }; break;
    case pathname.indexOf('/students/') >= 0:
      data = { title: 'Profil de l\'étudiant(e)', icon: FaceIcon }; break;
    case pathname.indexOf('/teachers/') >= 0:
      data = { title: 'Profil du professeur(e)', icon: SchoolIcon }; break;
    case pathname.indexOf('/topics/') >= 0:
      data = { title: 'Détail d\'un sujet', icon: ListAltIcon }; break;
    default:
      data = { title: 'Accueil', icon: HomeIcon }; break;
  }

  return (
    <div className={classes.toolbarTitle}>
      <Avatar className={classes.toolbarAvatar}>
        <data.icon />
      </Avatar>
      <Typography
        component="h2"
        variant="h6"
        color="inherit"
        noWrap
      >
        {data.title}
      </Typography>
    </div>
  );
};

class Dashboard extends React.Component {
  state = {
    open: true,
    fullScreen: document.fullscreenElement !== null,
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = (event) => {
    // the CRM home button is below : stop the click event
    event.preventDefault();
    this.setState({ open: false });
  };

  onLogin = (data) => {
    this.props.setJwtData(data);
  }

  onLogout = () => {
    clearAccessToken();
    this.props.setJwtData(null);
  }

  render() {
    const { classes, location } = this.props;
    const { fullScreen } = this.state;

    return (
      <JwtContext.Consumer>
        {(user) => {
          if (user && user.role === 'subscriber') {
            return (<div className={classes.root}><Forbidden /></div>);
          }
          return (
            !user ? (
              <div className={classes.root}>
                <CssBaseline />
                <SignIn onSuccess={this.onLogin} />
              </div>
            ) : (
              <div className={classes.root}>
                <CssBaseline />
                <AppBar
                  position="absolute"
                  elevation={0}
                  className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
                >
                  <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
                    <IconButton
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={this.handleDrawerOpen}
                      className={classNames(
                        classes.menuButton,
                        this.state.open && classes.menuButtonHidden,
                      )}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Title classes={classes} {...location} />
                    <SocketSync />
                    <UserBookmarks />
                    <Tooltip title="Plein écran">
                      <IconButton onClick={() => {
                        if (document.fullscreenElement === null) {
                          document.documentElement.requestFullscreen();
                          this.setState({ fullScreen: true });
                        } else {
                          document.exitFullscreen();
                          this.setState({ fullScreen: false });
                        }
                      }}>
                        {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                      </IconButton>
                    </Tooltip>
                    <Stats />
                    <Tooltip title="Se déconnecter">
                      <IconButton
                        color="inherit"
                        onClick={this.onLogout}
                      >
                        <LockIcon />
                      </IconButton>
                    </Tooltip>
                  </Toolbar>
                </AppBar>
                <Drawer
                  variant="permanent"
                  classes={{
                    paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                  }}
                  open={this.state.open}
                >
                  <div className={classes.sidebar}>
                    <List dense>
                      <ListItem
                        className={classes.sidebarTitle}
                        button
                        component={Link}
                        to="/"
                      >

                        <ListItemText
                          inset
                          primary={
                            <Box position="relative">
                              <Typography>ISP CRM</Typography>
                              {window.location.host !== 'crm.prepa-isp.fr' ? (
                                <Box className={classes.envBox}>
                                  <Typography className={classes.envTypography}>
                                    {window.location.host === 'crm.isp'
                                      ? 'LOCAL'
                                      : window.location.host === 'preprod-crm.prepa-isp.fr'
                                        ? 'PREPROD'
                                        : null
                                    }
                                  </Typography>
                                </Box>
                              ) : null}
                            </Box>
                          } />
                        <IconButton
                          className={classes.sidebarIcon}
                          onClick={this.handleDrawerClose}
                        >
                          <ChevronLeftIcon />
                        </IconButton>
                      </ListItem>
                    </List>
                    <Divider />
                    <SidebarUser user={user} />
                    <Divider />
                    <SidebarMenu user={user} />
                  </div>
                </Drawer>
                <main className={classes.content}>
                  <div className={classes.appBarSpacer} />
                  <Route exact path="/" render={(props) => {
                    return <Home user={user} />
                  }} />
                  <Route exact path="/timetable/calendar/:date" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:timetable"
                      yes={() =>
                        <CalendarView {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route exact path="/timetable" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:timetable"
                      yes={() =>
                        <Timetable {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route path="/payzen" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:payzen"
                      yes={() =>
                        <Payzen {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route path="/search/:type?" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:search"
                      yes={() =>
                        <Search
                          type={searchTypes[props.match.params.type ?? ''] ? props.match.params.type : Object.keys(searchTypes)[0]}
                        />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  {isSuperUser(user.login) ? (
                    <Route path="/logs" render={(props) => (
                      <Can
                        role={user.role}
                        action="view:logs"
                        yes={() =>
                          <Logs {...props} />
                        }
                        no={() => <Forbidden />}
                      />
                    )} />
                  ) : null}
                  <Route path="/news" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:news"
                      yes={() =>
                        <News {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route path="/trainings" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:trainings"
                      yes={() =>
                        <Trainings {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route exact path="/sessions" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:sessions"
                      yes={() =>
                        <Sessions {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route path="/sessions/:sessionId" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:sessions"
                      yes={() =>
                        <SessionDetails {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route path="/orders/:orderId?/:view?" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:customers"
                      yes={() =>
                        <Orders {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route path="/reglements" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:reglements"
                      yes={() =>
                        <Reglements {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route exact path="/students" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:students"
                      yes={() =>
                        <Students {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route path="/students/:studentId/:tab(orders)/:orderId/:view?" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:students"
                      yes={() =>
                        <StudentDetails {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route
                    exact
                    path={`/students/:studentId/:tab(${studentTabs.map((tab) => tab.value).join('|')})`}
                    render={(props) => {
                      return (
                        <Can
                          role={user.role}
                          action="view:students"
                          yes={() =>
                            <StudentDetails {...props} />
                          }
                          no={() => <Forbidden />}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path={`/students/:studentId`}
                    render={(props) => {
                      return (
                        <Can
                          role={user.role}
                          action="view:students"
                          yes={() =>
                            <Redirect to={{
                              pathname: `/students/${props.match.params.studentId}/profil`
                            }} />
                          }
                          no={() => <Forbidden />}
                        />
                      );
                    }}
                  />
                  <Route exact path="/teachers" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:teachers"
                      yes={() =>
                        <Teachers {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route exact path="/proofreading" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:proofreading"
                      yes={() =>
                        <Proofreading {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route path="/teachers/:teacherId" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:teachers"
                      yes={() =>
                        <TeacherDetails {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route exact path="/videos" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:videos"
                      yes={() =>
                        <Videos {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route exact path="/rattrapages" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:rattrapages"
                      yes={() =>
                        <Rattrapages {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route path="/documents" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:documents"
                      yes={() =>
                        <Documents {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route exact path="/topics" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:topics"
                      yes={() =>
                        <Topics {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route path="/topics/:topicId" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:topics"
                      yes={() =>
                        <TopicDetails {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route path="/homeworks" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:homeworks"
                      yes={() =>
                        <Homeworks {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route exact path="/podcasts" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:podcasts"
                      yes={() =>
                        <Podcasts {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route exact path="/polycopies" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:polycopies"
                      yes={() =>
                        <Polycopies {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route exact path="/statistics" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:statistics"
                      yes={() =>
                        <Statistics {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  <Route path="/settings" render={(props) => (
                    <Can
                      role={user.role}
                      action="view:settings"
                      yes={() =>
                        <Settings {...props} />
                      }
                      no={() => <Forbidden />}
                    />
                  )} />
                  {/* <Box mt={2} color={grey[500]} style={{ opacity: .25, fontSize: '10px' }}>
                      File icons made by <a href="https://www.flaticon.com/authors/dimitry-miroliubov">Dimitry Miroliubov</a> from www.flaticon.com
                    </Box> */}
                </main>
              </div>
            )
          );
        }}
      </JwtContext.Consumer>
    );
  }
}

Dashboard.propTypes = {
  location: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  setJwtData: PropTypes.func.isRequired,
};

export default withStyles(styles)(withRouter(Dashboard));
