import React, { Component, Fragment, forwardRef } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import { parse } from 'querystring';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  IconButton,
  LinearProgress,
  Paper,
  Slide,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  withStyles,
  DialogContent,
  Fade,
  Tooltip,
  InputBase,
} from '@material-ui/core';
import isEqual from 'lodash.isequal';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { green, grey, red } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import axios from 'axios';
import classNames from 'classnames';
import CancelIcon from '@material-ui/icons/Cancel';
import { tableStyles } from 'styles/datatable.css';
import { styles } from './Polycopies.css';
import withSortAndPagination from 'components/withSortAndPagination/withSortAndPagination';
import Http from 'services/Http';
import PolycopieStatusDialog from 'components/PolycopieStatusDialog/PolycopieStatusDialog';
import { shipmentLabels } from 'components/StudentHome/StudentHome';
import BlockIcon from '@material-ui/icons/Block';
import PanToolIcon from '@material-ui/icons/PanTool';
import Menu from 'components/Menu/Menu';
import { Shipment } from 'components/Shipment/Shipment';
import InvalidAddressesDialog from './InvalidAddressesDialog';

const sentLabels = ['Non envoyé', 'Envoyé', 'Annulé'];

export const getSentLabel = (value) => {
  const label = sentLabels.at(value);
  return label ?? sentLabels[0];
};

const sortableAndFilterableCells = [
  {
    cellInfo: { name: 'polycopie_name', label: 'Polycopié' },
    filtersInfo: [{
      checkbox: {
        tooltip: 'Uniquement les élèves qui répondent à toute la sélection',
      },
      type: 'autocomplete',
      multiple: true,
      filterBy: 'polycopieName',
      placeholder: '',
      fetcher: async (filterState) => {
        const { data } = await Http.get(`/polycopies/list?trainingName=${filterState.trainingName ? filterState.trainingName : ''}`);
        return data.map(({ name }) => name);
      },
    }],
  },
  {
    cellInfo: { name: 'discipline_name', label: 'Matière' },
    filtersInfo: [{ filterBy: 'disciplineName', placeholder: '' }],
  },
  {
    cellInfo: { name: 'first_name', label: 'Prénom' },
    filtersInfo: [{ filterBy: 'firstName', placeholder: '' }],
  },
  {
    cellInfo: { name: 'last_name', label: 'Nom' },
    filtersInfo: [{ filterBy: 'lastName', placeholder: '' }],
  },
  {
    cellInfo: { name: 'training_name', label: 'Formation' },
    filtersInfo: [{
      type: 'autocomplete',
      filterBy: 'trainingName',
      placeholder: '',
      fetcher: async () => {
        const { data } = await Http.get('/trainings?publishedOnly=false');
        return data.rows.map(({ title }) => title);
      },
    }],
  },
  {
    cellInfo: { name: 'sent', label: 'État' },
    filtersInfo: [{ filterBy: 'sent', placeholder: 'Tous', type: 'select', items: ['Non envoyé', 'Envoyé', 'Annulé'] }],
  },
  {
    cellInfo: { name: 'shipment', label: 'Envoi' },
    filtersInfo: [{
      filterBy: 'shipment',
      placeholder: 'Tous',
      type: 'select',
      items: Object.entries(shipmentLabels).map(([key, label]) => label),
    }],
  },
  {
    cellInfo: { name: 'sent_at', label: 'Envoyé le' },
    filtersInfo: [
      { filterBy: 'sentFrom', placeholder: 'Début', type: 'datePicker' },
      { filterBy: 'sentTo', placeholder: 'Fin', type: 'datePicker' },
    ],
  },
];

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SelectionMode = {
  FROM_ZERO: 'FROM_ZERO',
  FROM_ALL: 'FROM_ALL',
};

const initialState = () => {
  return {
    deleteDialog: false,
    disciplinesAutocompleteLoading: false,
    disciplinesAutocompleteOptions: [],
    disciplinesAutocompleteTextFieldValue: '',
    disciplinesAutocompleteValue: null,
    error: '',
    insertOrUpdateDialog: false,
    openDisciplinesAutocomplete: false,
    openError: false,
    openSuccess: false,
    polycopieId: null,
    polycopieLabel: '',
    success: '',
    selectAll: false,
    // 2 modes :
    // check all => store unchecked checkboxes
    // "from 0" => store added
    selectionMode: SelectionMode.FROM_ZERO,
    rowsTofilter: [],
    rowsToSave: [],
    loadingOpacity: false,
    statusDialog: null,
    studentId: null,
    setSentStateDialog: null,
    exportedEmails: null,
    invalidAddressesDialog: null,
    cogetefiInvalidAddressesDialog: null,
  };
};
class Polycopies extends Component {
  state = initialState();

  onCloseDeleteDialog = () => {
    this.setState({
      deleteDialog: false,
      polycopieLabel: '',
      polycopieId: null,
    });
  };

  onPolycopieDelete = async () => {
    if (this.state.deleteDialog) {
      try {
        await Http.delete(`/polycopies/${this.state.polycopieId}`);
        this.setState({
          success: `Le polycopie ${this.state.polycopieLabel} a été supprimé`,
          openSuccess: true,
          deleteDialog: false,
          polycopieLabel: '',
          polycopieId: null,
        });
      } catch ({ data }) {
        this.setState({
          error: data ? Object.values(data) : ['Une erreur est survenue'],
          openError: true,
          deleteDialog: false,
          polycopieLabel: '',
          polycopieId: null,
        });
      }
      this.props.reload();
    }
  };

  onCloseUpdateStatusDialog = () => {
    this.setState({ statusDialog: null });
  };

  onUpdateStatus = async () => {
    const { statusDialog } = this.state;
    this.setState({
      success: `Le polycopié ${statusDialog.polycopieLabel} a été passé en état "${getSentLabel(statusDialog.polycopieSent)}"`,
      openSuccess: true,
      statusDialog: null,
      loadingOpacity: false,
    });
    this.props.reload();
  };

  downloadBuffer = (buffer, filename) => {
    const url = URL.createObjectURL(new Blob([buffer.data]));
    const link = global.document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    global.document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  docxStickersExport = async () => {
    this.setState({ loadingOpacity: true });
    const { selectionMode, rowsTofilter, rowsToSave } = this.state;
    try {
      const buffer = await Http.post('/polycopies/docxStickersExport', {
        // Mimic HOC request
        params: {
          offset: 0,
          limit: this.props.count,
          ...this.props.filterState,
        },
        // Send save/filter info
        selection: {
          selectionMode,
          rowsTofilter,
          rowsToSave,
        },
      }, {
        responseType: 'blob',
      });
      this.downloadBuffer(buffer, `polycopies-etiquettes-${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.docx`);
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
    this.setState({ loadingOpacity: false });
  };

  csvExport = async () => {
    this.setState({ loadingOpacity: true });
    const { selectionMode, rowsTofilter, rowsToSave } = this.state;
    try {
      // const buffer = await Http.post('/polycopies/csvExport', {
      const buffer = await Http.post('/polycopies/xlsExport', {
        // Mimic HOC request
        params: {
          offset: 0,
          limit: this.props.count,
          ...this.props.filterState,
        },
        // Send save/filter info
        selection: {
          selectionMode,
          rowsTofilter,
          rowsToSave,
        },
      }, {
        responseType: 'blob',
      });
      // this.downloadBuffer(buffer, `polycopies-${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.csv`);
      this.downloadBuffer(buffer, `polycopies-${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.xlsx`);
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
    this.setState({ loadingOpacity: false });
  }

  /**
   * 
   * @param {0 | 1 | 2} value 
   * @returns 
   */
  setSentState = async (value, sentAt) => {
    this.setState({ setSentStateDialog: null, loadingOpacity: true });
    const { selectionMode, rowsTofilter, rowsToSave } = this.state;
    try {
      await Http.post('/polycopies/setSentState', {
        params: {
          offset: 0,
          limit: this.props.count,
          ...this.props.filterState,
        },
        // Send save/filter info
        selection: {
          selectionMode,
          rowsTofilter,
          rowsToSave,
        },
        value,
        sentAt,
      });
      await this.props.reload();
      this.setState({
        success: `La sélection a été marquée comme "${getSentLabel(value)}"`,
        loadingOpacity: false,
        openSuccess: true,
        selectionMode,
        rowsTofilter,
        rowsToSave,
      });
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        loadingOpacity: false,
        openError: true,
        setSentStateDialog: null,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const filtersDidUpdate = isEqual(prevProps.filterState, this.props.filterState) === false;
    if (filtersDidUpdate) {
      this.setState(initialState());
    }
  }

  onSelectAllClick = ({ target }) => {
    const { checked } = target;
    this.setState({
      selectAll: checked,
      rowsTofilter: [],
      rowsToSave: [],
      selectionMode: checked ? SelectionMode.FROM_ALL : SelectionMode.FROM_ZERO,
    });
  };

  rowsAreEqual = (r1, r2) => {
    const picks = ['discipline_id', 'polycopie_id', 'student_id', 'training_id'];
    const pr1 = {};
    const pr2 = {};
    picks.forEach((p) => {
      pr1[p] = r1[p];
      pr2[p] = r2[p];
    });
    return isEqual(pr1, pr2);
  };

  isChecked = (rowToCompare) => {
    return (this.state.selectionMode === SelectionMode.FROM_ZERO)
      ? this.state.rowsToSave.findIndex((row) => this.rowsAreEqual(row, rowToCompare)) !== -1
      : this.state.rowsTofilter.findIndex((row) => this.rowsAreEqual(row, rowToCompare)) === -1
  };

  getSelectedRows = () => {
    const { count } = this.props;
    return this.state.selectionMode === SelectionMode.FROM_ZERO
      ? this.state.rowsToSave.length
      : count - this.state.rowsTofilter.length;
  };

  onSelectOne = ({ target }) => {
    const { value } = target;
    const { rows } = this.props;
    const rowsToSave = [...this.state.rowsToSave];
    const rowsTofilter = [...this.state.rowsTofilter];
    const { selectionMode } = this.state;
    if (selectionMode === SelectionMode.FROM_ZERO) {
      const index = rowsToSave.findIndex((row) => this.rowsAreEqual(row, rows[value]));
      if (index !== -1) {
        rowsToSave.splice(index, 1);
      } else {
        rowsToSave.push(rows[value]);
      }
    } else if (selectionMode === SelectionMode.FROM_ALL) {
      const index = rowsTofilter.findIndex((row) => this.rowsAreEqual(row, rows[value]));
      if (index !== -1) {
        rowsTofilter.splice(index, 1);
      } else {
        rowsTofilter.push(rows[value]);
      }
    }
    this.setState({
      rowsTofilter,
      rowsToSave,
    });
  };

  source = null;

  save = async (updateMode) => {
    const {
      disciplinesAutocompleteValue,
      polycopieLabel,
      polycopieId
    } = this.state;
    try {
      const body = {
        name: polycopieLabel,
        discipline_id: disciplinesAutocompleteValue.id,
      };
      if (updateMode) {
        await Http.put(`/polycopies/${polycopieId}`, body);
        this.setState({
          success: `Le polycopié ${polycopieLabel} a été mis à jour`,
          openSuccess: true,
          polycopieLabel: '',
          disciplinesAutocompleteValue: null,
          insertOrUpdateDialog: false,
        });
      } else {
        await Http.post('/polycopies', body);
        this.setState({
          success: `Le polycopié ${polycopieLabel} a été ajouté à la matière ${disciplinesAutocompleteValue.name}`,
          openSuccess: true,
          polycopieLabel: '',
          disciplinesAutocompleteValue: null,
          insertOrUpdateDialog: false,
        });
      }
      this.props.reload();
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
  };

  disciplinesAutocomplete = async (search) => {
    if (this.source) {
      this.source.cancel('Operation /disciplines/autocomplete/:search canceled by the user.');
    }
    this.setState({
      disciplinesAutocompleteLoading: true,
      disciplinesAutocompleteTextFieldValue: search,
    });
    try {
      this.source = axios.CancelToken.source();
      const { data } = await Http.get(`/disciplines/autocomplete/${search}`, {
        cancelToken: this.source.token,
      });
      this.setState({
        disciplinesAutocompleteLoading: false,
        disciplinesAutocompleteOptions: data.rows,
      });
    } catch (e) {
      console.log(e);
    }
  };

  emailExport = async () => {
    this.setState({ loadingOpacity: true });
    const { selectionMode, rowsTofilter, rowsToSave } = this.state;
    try {
      const { data } = await Http.post('/polycopies/getEmailsFromSelection', {
        params: {
          offset: 0,
          limit: this.props.count,
          ...this.props.filterState,
        },
        // Send save/filter info
        selection: {
          selectionMode,
          rowsTofilter,
          rowsToSave,
        },
      });
      this.setState({
        exportedEmails: data.map(({ student_email }) => student_email).join(';'),
        loadingOpacity: false,
      });
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        loadingOpacity: false,
        openError: true,
      });
    }
  };

  copyEmailsToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(this.state.exportedEmails);
      this.setState({
        success: 'Emails copiés !',
        openSuccess: true,
      });
    } catch (err) {
      this.setState({
        error: 'Une erreur est survenue',
        openError: true,
      });
    }
    this.setState({
      exportedEmails: null,
    });
  }

  cogetefiExport = async () => {
    this.setState({ loadingOpacity: true });
    const { selectionMode, rowsTofilter, rowsToSave } = this.state;
    try {
      const buffer = await Http.post('/polycopies/cogetefiExport', {
        // Mimic HOC request
        params: {
          offset: 0,
          limit: this.props.count,
          ...this.props.filterState,
        },
        // Send save/filter info
        selection: {
          selectionMode,
          rowsTofilter,
          rowsToSave,
        },
      }, {
        responseType: 'blob',
      });
      this.downloadBuffer(buffer, `polycopies-cogetefi-${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.xlsx`);
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
    this.setState({ loadingOpacity: false });
  };

  cogetefiCheckExportValidity = async () => {
    this.setState({ loadingOpacity: true });
    const { selectionMode, rowsTofilter, rowsToSave } = this.state;
    try {
      const { data } = await Http.post('/polycopies/colishipCheckExportValidity', {
        // Mimic HOC request
        params: {
          offset: 0,
          limit: this.props.count,
          ...this.props.filterState,
        },
        // Send save/filter info
        selection: {
          selectionMode,
          rowsTofilter,
          rowsToSave,
        },
      });
      if (data.length > 0) {
        this.setState({
          cogetefiInvalidAddressesDialog: data,
        });
      } else {
        return this.cogetefiExport();
      }
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
    this.setState({ loadingOpacity: false });
  }

  colishipCheckExportValidity = async () => {
    this.setState({ loadingOpacity: true });
    const { selectionMode, rowsTofilter, rowsToSave } = this.state;
    try {
      const { data } = await Http.post('/polycopies/colishipCheckExportValidity', {
        // Mimic HOC request
        params: {
          offset: 0,
          limit: this.props.count,
          ...this.props.filterState,
        },
        // Send save/filter info
        selection: {
          selectionMode,
          rowsTofilter,
          rowsToSave,
        },
      });
      if (data.length > 0) {
        this.setState({
          invalidAddressesDialog: data,
        });
      } else {
        return this.colishipExport();
      }
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
    this.setState({ loadingOpacity: false });
  }

  colishipExport = async () => {
    this.setState({ loadingOpacity: true });
    const { selectionMode, rowsTofilter, rowsToSave } = this.state;
    try {
      const buffer = await Http.post('/polycopies/colishipExport', {
        // Mimic HOC request
        params: {
          offset: 0,
          limit: this.props.count,
          ...this.props.filterState,
        },
        // Send save/filter info
        selection: {
          selectionMode,
          rowsTofilter,
          rowsToSave,
        },
      }, {
        responseType: 'blob',
      });
      this.downloadBuffer(buffer, `polycopies-coliship-${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.csv`);
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
    this.setState({ loadingOpacity: false });
  }

  render() {
    const {
      classes,
      count,
      loading,
      renderPagination,
      renderSortableAndFilterable,
      rows,
    } = this.props;
    const {
      deleteDialog,
      disciplinesAutocompleteLoading,
      disciplinesAutocompleteOptions,
      disciplinesAutocompleteTextFieldValue,
      disciplinesAutocompleteValue,
      error,
      insertOrUpdateDialog,
      loadingOpacity,
      openDisciplinesAutocomplete,
      openError,
      openSuccess,
      polycopieLabel,
      polycopieId,
      selectAll,
      success,
      statusDialog,
      setSentStateDialog,
      exportedEmails,
      invalidAddressesDialog,
      cogetefiInvalidAddressesDialog,
    } = this.state;
    const loadingStyle = { opacity: (loading || loadingOpacity) ? .5 : 1 };
    const updateMode = polycopieId !== null;

    const items = [
      {
        label: 'COGETEFI',
        onClick: this.cogetefiCheckExportValidity,
        buttonProps: {
          size: 'small',
          variant: 'outlined',
          color: 'default',
          disableElevation: true,
        },
      },
      {
        label: 'COLISHIP',
        onClick: this.colishipCheckExportValidity,
        buttonProps: {
          size: 'small',
          variant: 'outlined',
          color: 'default',
          disableElevation: true,
        },
      },
      {
        label: 'EMAILS',
        onClick: this.emailExport,
        buttonProps: {
          size: 'small',
          variant: 'outlined',
          color: 'default',
          disableElevation: true,
        },
      },
      {
        label: 'XLS',
        onClick: this.csvExport,
        buttonProps: {
          size: 'small',
          variant: 'outlined',
          color: 'default',
          disableElevation: true,
        },
      },
      {
        label: 'STICKERS',
        onClick: this.docxStickersExport,
        buttonProps: {
          size: 'small',
          variant: 'outlined',
          color: 'default',
          disableElevation: true,
        },
      },
      {
        label: 'ENVOYER',
        onClick: () => {
          this.setState({
            setSentStateDialog: {
              polycopieSent: 1,
            },
          });
        },
        buttonProps: {
          size: 'small',
          variant: 'contained',
          color: 'default',
          disableElevation: true,
        },
      },
      {
        label: 'ANNULER',
        onClick: () => {
          this.setState({
            setSentStateDialog: {
              polycopieSent: 2,
            },
          });
        },
        buttonProps: {
          size: 'small',
          variant: 'contained',
          color: 'primary',
          disableElevation: true,
        },
      },
      {
        label: 'PAS ENVOYER',
        onClick: () => {
          this.setState({
            setSentStateDialog: {
              polycopieSent: 0,
            },
          });
        },
        buttonProps: {
          size: 'small',
          variant: 'contained',
          color: 'secondary',
          disableElevation: true,
        },
      },
    ];

    return (
      <Fragment>
        <ErrorSnackbar
          message={error}
          onClose={() => this.setState({ openError: false })}
          open={openError}
        />
        <SuccessSnackbar
          message={success}
          onClose={() => this.setState({ openSuccess: false })}
          open={openSuccess}
        />
        <Paper elevation={1} className={classes.root} style={loadingStyle}>
          {invalidAddressesDialog !== null ? (
            <InvalidAddressesDialog
              onClose={() => {
                this.setState({ invalidAddressesDialog: null });
              }}
              open={invalidAddressesDialog !== null}
              invalidAddresses={invalidAddressesDialog}
              onSuccess={this.colishipExport}
            />
          ) : null}
          {cogetefiInvalidAddressesDialog !== null ? (
            <InvalidAddressesDialog
              onClose={() => {
                this.setState({ cogetefiInvalidAddressesDialog: null });
              }}
              open={cogetefiInvalidAddressesDialog !== null}
              invalidAddresses={cogetefiInvalidAddressesDialog}
              onSuccess={this.cogetefiExport}
            />
          ) : null}
          <PolycopieStatusDialog
            open={statusDialog !== null}
            {...statusDialog}
            onClose={this.onCloseUpdateStatusDialog}
            onConfirm={this.onUpdateStatus}
          />
          <PolycopieStatusDialog
            open={setSentStateDialog !== null}
            bulkMode
            {...setSentStateDialog}
            onClose={() => {
              this.setState({ setSentStateDialog: null })
            }}
            onConfirm={(date) => {
              this.setSentState(setSentStateDialog.polycopieSent, date);
            }}
          />
          <ConfirmationDialog
            open={deleteDialog}
            title="Attention"
            message={`Êtes-vous sûr de vouloir supprimer le polycopié ${this.state.polycopieLabel} et les étudiants liés ?`}
            onClose={this.onCloseDeleteDialog}
            onConfirm={this.onPolycopieDelete}
          />
          <Dialog
            open={insertOrUpdateDialog}
            onClose={() => {
              this.setState({ insertOrUpdateDialog: false });
            }}
            fullWidth
            maxWidth="md"
            TransitionComponent={Transition}
          >
            <Toolbar className={classes.toolbar}>
              <IconButton
                className={classes.closeButton}
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ insertOrUpdateDialog: false });
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography
                color="inherit"
                variant="h6"
                className={classes.title}
              >
                {`${updateMode ? 'Modifier' : 'Ajouter'} un polycopié`}
              </Typography>
              <Button
                onClick={() => {
                  this.save(updateMode);
                }}
                variant="contained"
                color="secondary"
                disableElevation
                disabled={polycopieLabel.length === 0 || disciplinesAutocompleteValue === null}
              >
                {`${updateMode ? 'Mettre à jour' : 'Créer'} un polycopié`}
              </Button>
            </Toolbar>

            <DialogContent>
              <Box mt={1}>
                <Autocomplete
                  noOptionsText="Aucune matière, veuillez saisir du texte"
                  value={disciplinesAutocompleteValue}
                  open={openDisciplinesAutocomplete}
                  onOpen={() => {
                    this.setState({ openDisciplinesAutocomplete: true });
                  }}
                  onClose={() => {
                    this.setState({ openDisciplinesAutocomplete: false });
                  }}
                  onChange={(event, option) => {
                    this.setState({
                      disciplinesAutocompleteValue: option,
                    });
                  }}
                  getOptionSelected={(option, value) => option.name === value.name}
                  getOptionLabel={(option) => option.name}
                  options={disciplinesAutocompleteOptions}
                  loading={disciplinesAutocompleteLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(event) => {
                        this.disciplinesAutocomplete(event.target.value);
                      }}
                      value={disciplinesAutocompleteTextFieldValue}
                      label="Matière"
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {disciplinesAutocompleteLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
              <Box my={2}>
                <TextField
                  fullWidth
                  label="Libellé"
                  required
                  value={polycopieLabel}
                  onChange={(event) => {
                    this.setState({
                      polycopieLabel: event.target.value,
                    });
                  }}
                />
              </Box>
            </DialogContent>
          </Dialog>
          <Toolbar>
            <Fade in={this.getSelectedRows() > 0}>
              <Box
                flexGrow="1"
                display="flex"
                alignItems="center"
              >
                <Box flexGrow=".5" pr={1}>
                  <LinearProgress
                    variant="determinate"
                    value={this.getSelectedRows() * 100 / count}
                    classes={{
                      root: classes.linearProgress,
                    }}
                  />
                </Box>
                <Typography>
                  {`${this.getSelectedRows()} / ${count} polycopié${this.getSelectedRows() > 1 ? 's' : ''} sélectionné${this.getSelectedRows() > 1 ? 's' : ''}`}
                </Typography>
                <Box
                  pl={1}
                  className={classes.visibleForSmallScreen}
                  display="flex"
                  style={{ gap: '8px' }}
                  justifyContent="flex-end"
                  flex={1}
                >
                  <Menu
                    title="Actions"
                    items={items}
                  />
                </Box>
                <Box
                  display="flex"
                  style={{ gap: '8px' }}
                  className={classes.visibleForBigScreen}
                  justifyContent="flex-end"
                  flex={1}
                >
                  {items.map((item) => {
                    return (
                      <Button
                        key={item.label}
                        {...item.buttonProps}
                        onClick={item.onClick}
                      >
                        {item.label}
                      </Button>
                    );
                  })}
                </Box>
              </Box>
            </Fade>
            <Box ml={1}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => {
                  this.setState({
                    disciplinesAutocompleteValue: null,
                    insertOrUpdateDialog: true,
                    polycopieLabel: '',
                    polycopieId: null,
                  });
                }}
              >
                Créer un polycopié
              </Button>
            </Box>
          </Toolbar>
          {exportedEmails ? (
            <Box
              m={1}
              display="flex"
              alignItems="center"
              style={{ gap: '12px' }}
            >
              <InputBase
                fullWidth
                multiline
                value={exportedEmails}
                classes={{
                  root: classes.field,
                  input: classes.fieldInput,
                }}
                id="copy-emails"
              />
              <IconButton onClick={this.copyEmailsToClipboard}>
                <FileCopyIcon />
              </IconButton>
            </Box>
          ) : null}
          <Table size="small" className={classNames(classes.table, classes.denseTable)}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell padding="checkbox"><Checkbox onChange={this.onSelectAllClick} checked={selectAll} /></TableCell>
                {sortableAndFilterableCells.map(({ cellInfo, filtersInfo }) => renderSortableAndFilterable(cellInfo, filtersInfo))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((p, index) => /*console.log(p) ||*/(
                <TableRow key={`${p.polycopie_id}-${p.student_id}-${p.training_id}-${p.session_name}`}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      value={index}
                      onChange={this.onSelectOne}
                      checked={this.isChecked(p)}
                    />
                  </TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      style={{ gap: '8px' }}
                      alignItems="center"
                    >
                      <Typography size="inherit">{p.polycopie_name}</Typography>
                      {p.origin === 'manual' ?
                        <Tooltip title="Polycopié ajouté manuellement">
                          <PanToolIcon style={{ opacity: .25 }} fontSize="small" />
                        </Tooltip>
                        : null
                      }
                    </Box>
                  </TableCell>
                  <TableCell>{p.discipline_name}</TableCell>
                  <TableCell>{p.first_name}</TableCell>
                  <TableCell>{p.last_name}</TableCell>
                  <TableCell>{p.training_name}</TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="center">
                      {(p.sent === 1 || !p.sent)
                        ? (
                          <IconButton
                            edge="start"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              this.setState({
                                statusDialog: {
                                  polycopieLabel: p.polycopie_name,
                                  polycopieId: p.polycopie_id,
                                  studentId: p.student_id,
                                  polycopieSent: p.sent === 1 ? 0 : 1,
                                  polycopieSentAt: p.sent_at,
                                },
                              });
                            }}
                          >
                            <CheckCircleIcon style={{ color: p.sent ? green[400] : grey[200] }} />
                          </IconButton>
                        ) : p.sent === 2
                          ? (
                            <IconButton
                              edge="start"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                this.setState({
                                  statusDialog: {
                                    polycopieLabel: p.polycopie_name,
                                    polycopieId: p.polycopie_id,
                                    studentId: p.student_id,
                                    polycopieSent: 0,
                                    polycopieSentAt: p.sent_at,
                                  },
                                });
                              }}
                            >
                              <CancelIcon style={{ color: red[400] }} />
                            </IconButton>
                          ) : null
                      }
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      px={2}
                    // style={{ gap: '6px' }}
                    >
                      <Shipment shipment={p.shipment} />
                      {p.do_not_send === 1 ? (
                        <Tooltip title="Attention : exclusion des polycopiés">
                          <BlockIcon fontSize="small" color="secondary" />
                        </Tooltip>
                      ) : null}
                    </Box>
                  </TableCell>
                  <TableCell>{p.sent_at ? format(new Date(p.sent_at), 'dd MMM yyyy', { locale: frLocale }) : null}</TableCell>
                  <TableCell>
                    <Box display="flex">
                      <Tooltip title={`Editer les polycopiés "${p.polycopie_name}"`}>
                        <IconButton
                          edge="start"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            this.setState({
                              disciplinesAutocompleteValue: {
                                id: p.discipline_id,
                                name: p.discipline_name,
                              },
                              insertOrUpdateDialog: true,
                              polycopieLabel: p.polycopie_name,
                              polycopieId: p.polycopie_id,
                            });
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Box ml={1}>
                        <Tooltip title={`Supprimer les poly "${p.polycopie_name}"`}>
                          <IconButton
                            edge="start"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              this.setState({
                                deleteDialog: true,
                                polycopieLabel: p.polycopie_name,
                                polycopieId: p.polycopie_id,
                              });
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {renderPagination()}
        </Paper>
      </Fragment>
    );
  }

}

Polycopies.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles((theme) => ({
  ...tableStyles(theme),
  ...styles(theme),
}))(
  withSortAndPagination(Polycopies, 'polycopies', 'polycopie_name', 'asc', async () => {
    const search = parse(window.location.search.substring(1));
    if (!search.student_ids) {
      // filtre de formation par défaut : on ne veut plus pour le moment
      // const { data } = await Http.get('/trainings/mostRecent');
      // return { trainingName: data[0].title };
    }
    return { studentIds: search.student_ids };
  }
  )
);
