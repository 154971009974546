import { Box, Button, useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { getInPersonColor } from './TimetableAcl';

type Props = {
  entryType?: 'all' | 'zoomOnly' | 'inPersonOnly';
  filter: (value: Props['entryType']) => void;
};

export default function EntryTypeFilter({
  entryType,
  filter,
}: Props) {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      style={{
        gap: theme.spacing(1),
        flexDirection: 'row',
      }}
    >
      <Button
        disableElevation
        size="small"
        variant="contained"
        onClick={() => {
          filter('all');
        }}
        startIcon={entryType === 'all' || !entryType ? <CheckIcon /> : null}
      >
        Tout
      </Button>
      <Button
        disableElevation
        size="small"
        variant="contained"
        onClick={() => {
          filter('inPersonOnly');
        }}
        style={{
          background: getInPersonColor({ in_person: 1 }),
          color: '#fff',
        }}
        startIcon={entryType === 'inPersonOnly' ? <CheckIcon /> : null}
      >
        Présentiel
      </Button>
      <Button
        disableElevation
        size="small"
        variant="contained"
        onClick={() => {
          filter('zoomOnly');
        }}
        style={{
          background: getInPersonColor({ meeting_id: 1 }),
          color: '#fff',
        }}
        startIcon={entryType === 'zoomOnly' ? <CheckIcon /> : null}
      >
        Zoom
      </Button>
    </Box>
  );
};
