import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Button, Card, CardContent, CardHeader, Checkbox, Fade, FormControlLabel, FormGroup, IconButton, InputBase, NativeSelect, withStyles } from '@material-ui/core';
import { tableStyles } from 'styles/datatable.css';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import EuroIcon from '@material-ui/icons/Euro';
import fr from 'date-fns/locale/fr';
import format from 'date-fns/format';
import DeleteIcon from '@material-ui/icons/Delete';

registerLocale('fr', fr);
setDefaultLocale('fr');

const filter = createFilterOptions();

export const mapPaymentsLabels = {
  payzenstd: 'Paiement CB',
  'cheque-several': 'Chèque(s) à l’ordre de Prépa ISP',
  payzenmulti: 'Paiement CB en plusieurs fois',
  cash: 'Espèces',
  transfer: 'Virement',
};

const CustomDateInput = forwardRef(({ value, onClick }, ref) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" style={{ gap: '12px' }}>
      <Button
        onClick={onClick}
        ref={ref}
        variant="outlined"
        size="small"
        endIcon={<CalendarIcon />}
      >
        {value}
      </Button>
    </Box>
  );
});

class OrdersPaymentsItem extends Component {
  state = {
    banks: [],
    createBankInProgress: false,
    comment: '',
    date: new Date(),
    reference: '',
    amount: '',
    paymentType: '',
    name: '',
  }

  componentDidMount() {
    const { amount, comment, date, id, bank_id, reference, paymentType, banks } = this.props;
    const bank = banks.find(({ id }) => id === bank_id);
    this.setState({
      amount,
      comment,
      date: date instanceof Date ? date : new Date(date),
      id,
      name: bank ? bank.name : '',
      reference,
      paymentType,
    });
  }

  render() {
    const { amount, comment, date, id, name, reference, paymentType } = this.state;
    const { createBank, createBankInProgress, banks, classes, index, onDelete, valid } = this.props;
    return (
      <Fade in appear>
        <Card
          style={{
            height: '100%',
          }}
        >
          <CardHeader
            style={{ paddingBottom: 0 }}
            avatar={
              <Avatar>{`${index.toString().padStart(2, '0')}`}</Avatar>
            }
            title={
              <Box display="flex" alignItems="center" style={{ gap: '12px' }}>
                <input type="hidden" name={`id_${index}`} value={id ?? ''} />
                <ReactDatePicker
                  selected={date}
                  onChange={(date) => {
                    this.setState({ date });
                  }}
                  placeholderText="jj/mm/aaaa"
                  dateFormat="dd/MM/yyyy"
                  customInput={<CustomDateInput />}
                  withPortal
                />
                <input type="hidden" name={`date_${index}`} value={format(date, 'yyyy-MM-dd')} />
                <InputBase
                  required
                  endAdornment={<EuroIcon fontSize="small" />}
                  style={{ width: '95px', display: 'flex' }}
                  name={`amount_${index}`}
                  classes={{
                    root: classes.field,
                    input: classes.fieldInput,
                  }}
                  value={amount ?? ''}
                  placeholder="Montant"
                  inputProps={{
                    type: 'number',
                  }}
                  onChange={(event) => {
                    this.setState({
                      amount: event.target.value,
                    });
                    this.props.onAmountChange();
                  }}
                />
                <NativeSelect
                  required
                  value={paymentType}
                  onChange={(event) => {
                    this.setState({
                      paymentType: event.target.value,
                    });
                  }}
                  name={`paymentType_${index}`}
                  style={{
                    paddingLeft: '6px',
                    flex: 1,
                    fontSize: '12px',
                    height: 'auto',
                  }}
                >
                  <option value="">Type de paiement</option>
                  {Object.entries(mapPaymentsLabels).map(([optionValue, optionLabel]) => {
                    return (
                      <option key={optionValue} value={optionValue}>{optionLabel}</option>
                    );
                  })}
                </NativeSelect>
                <IconButton
                  onClick={() => onDelete(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            }
          />
          <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
            <Box my={1} display="flex" alignItems="center" style={{ gap: '6px' }}  >
              <Autocomplete
                disabled={createBankInProgress}
                value={name ?? ''}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    this.setState({
                      name: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    createBank(newValue.inputValue);
                    this.setState({
                      name: newValue.inputValue,
                    });
                  } else {
                    this.setState({
                      name: newValue,
                    });
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  // Suggest the creation of a new value
                  if (params.inputValue !== '' && banks.find(({ name }) => name === params.inputValue) === undefined) {
                    filtered.push({
                      inputValue: params.inputValue,
                      name: `Ajouter la banque : "${params.inputValue}"`,
                    });
                  }
                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={banks}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.name;
                }}
                renderOption={(option) => option.name}
                style={{ width: 300 }}
                freeSolo
                renderInput={(params) => {
                  return (
                    <Box ref={params.InputProps.ref}>
                      <InputBase
                        {...params.inputProps}
                        name={`bank_${index}`}
                        label="Banque"
                        variant="standard"
                        classes={{
                          root: classes.field,
                          input: classes.fieldInput,
                        }}
                        endAdornment={params.InputProps.endAdornment}
                        placeholder="Banque"
                      />
                    </Box>
                  );
                }}
              />
              <InputBase
                name={`reference_${index}`}
                classes={{
                  root: classes.field,
                  input: classes.fieldInput,
                }}
                value={reference ?? ''}
                placeholder="Référence"
                onChange={(event) => {
                  this.setState({
                    reference: event.target.value,
                  });
                }}
              />
              <InputBase
                fullWidth
                multiline
                name={`comment_${index}`}
                classes={{
                  root: classes.field,
                  input: classes.fieldInput,
                }}
                value={comment ?? ''}
                placeholder="Commentaire"
                onChange={(event) => {
                  this.setState({
                    comment: event.target.value,
                  });
                }}
              />
              <FormGroup>
                <FormControlLabel
                  className={classes.smallMuiFormControlLabel}
                  control={
                    <Checkbox
                      defaultChecked={valid === null || valid === true}
                      name={`valid_${index}`}
                      value="1"
                    />
                  }
                  label="Valide"
                />
              </FormGroup>
            </Box>
          </CardContent>
        </Card>
      </Fade>
    );
  }
}

OrdersPaymentsItem.propTypes = {
  banks: PropTypes.array.isRequired,
  createBankInProgress: PropTypes.bool.isRequired,
  createBank: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  onAmountChange: PropTypes.func.isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  comment: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  bank_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reference: PropTypes.string.isRequired,
  paymentType: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default withStyles((theme) => ({
  ...tableStyles(theme),
}))(
  OrdersPaymentsItem
);
