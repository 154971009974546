import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, DialogActions, DialogContent, Fade, Grid, Typography, withStyles } from '@material-ui/core';
import { tableStyles } from 'styles/datatable.css';
import Http from 'services/Http';
import OrdersPaymentsItem from './OrdersPaymentsItem';
import { Alert } from '@material-ui/lab';
import format from 'date-fns/format';
import addMonths from 'date-fns/addMonths';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

export const formatAmount = (n) => new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(n);

class OrdersPayments extends Component {

  state = {
    banks: [],
    createBankInProgress: false,
    paymentsTotal: 0,
    rows: [],
    loading: true,
    openConfirmDelete: false,
  }

  async componentDidMount() {
    const { order } = this.props;
    const { data: banks } = await Http.get('/orders/banks');
    const { data: rows } = await Http.get(`/orders/payments/${order.id}`);
    this.setState({ banks, rows, loading: false });
    this.onAmountChange();
  }


  loadPayments = async () => {
    const { order } = this.props;
    this.setState({ loading: true });
    const { data: rows } = await Http.get(`/orders/payments/${order.id}`);
    this.setState({ rows, loading: false });
    this.onAmountChange();
  };

  createBank = async (name) => {
    this.setState({ createBankInProgress: true });
    await Http.post('/orders/banks', {
      name,
    });
    const { data } = await Http.get('/orders/banks');
    this.setState({
      banks: data,
      createBankInProgress: false,
    });
  }

  getFormData = () => {
    const { banks } = this.state;
    const { order } = this.props;
    const formData = new FormData(document.getElementById('payments-form'));
    const data = Array.from(formData).reduce((prev, [key, value]) => {
      const tokens = key.split('_');
      const index = parseInt(tokens.at(-1)) - 1;
      const clone = [...prev];
      if (!clone.at(index)) {
        clone[index] = {};
      }
      clone.at(index)[`${tokens.slice(0, -1)}`] = value;
      return clone;
    }, []).map((row) => {
      const bank = banks.find((bank) => bank.name === row.bank);
      return {
        ...row,
        bank_id: bank ? bank.id : null,
        order_id: order.id,
        valid: row.valid === '1' ? true : false,
      };
    });
    return data;
  }

  onSubmit = async (event) => {
    event.preventDefault();
    await Http.post('/orders/savePayments', {
      payments: this.getFormData(),
    });
    this.loadPayments();
  }

  onAmountChange = () => {
    const data = this.getFormData();
    const paymentsTotal = data.reduce((prev, current) => {
      const currentAmount = parseFloat(current.amount);
      if (!current.valid) {
        return prev;
      }
      return prev + (isNaN(currentAmount) ? 0 : parseFloat(current.amount));
    }, 0);
    this.setState({
      paymentsTotal,
    });
  }

  onDelete = async (index) => {
    const { rows, deleteIndex } = this.state;
    const row = rows.at(deleteIndex - 1);
    if (row && row.id) {
      await Http.delete(`/orders/payments/${row.id}`);
      await this.loadPayments();
      this.onAmountChange();
    } else {
      const nextRows = rows.filter((_, key) => deleteIndex - 1 !== key);
      this.setState({
        rows: nextRows,
        paymentsTotal: nextRows.reduce((p, c) => p + parseFloat(c.amount), 0),
      });
    }
  }

  onDeleteConfirm = (deleteIndex) => {
    this.setState({
      openConfirmDelete: true,
      deleteIndex,
    });
  }

  render() {
    const { createBankInProgress, banks, rows, paymentsTotal, loading, openConfirmDelete } = this.state;
    const { close, order } = this.props;
    const orderTotal = parseFloat(order.total);
    const paymentState = paymentsTotal > orderTotal
      ? 'toomuchpaid'
      : paymentsTotal === orderTotal
        ? 'paid'
        : 'notenoughpaid';
    return (
      <Fragment>
        <DialogContent>
          <form id="payments-form" onSubmit={this.onSubmit}>
            <Box>
              <ConfirmationDialog
                message="Êtes-vous sûr de vouloir supprimer ce paiement ? (et tout autre paiement non enregistré)"
                title="Votre attention"
                open={openConfirmDelete}
                onConfirm={() => {
                  this.setState({
                    openConfirmDelete: false,
                  });
                  this.onDelete();
                }}
                onClose={() => {
                  this.setState({
                    openConfirmDelete: false,
                  });
                }}
              />
              <Grid container spacing={2}>
                {
                  loading ? (
                    <Box my={2}>
                      <Typography>Chargement...</Typography>
                    </Box>
                  )
                    : rows.length === 0
                      ? (
                        <Box display="flex" justifyContent="center" flex="1" m={2}>
                          <Alert severity="warning" >
                            Aucun paiement enregistré
                          </Alert>
                        </Box>
                      )
                      : rows.map((row, index) => {
                        return (
                          <Grid
                            item
                            key={`${row.date ?? new Date().toISOString()}-${index}`}
                            xs={12}
                            lg={6}
                          >
                            <OrdersPaymentsItem
                              banks={banks}
                              createBankInProgress={createBankInProgress}
                              createBank={this.createBank}
                              index={index + 1}
                              {...row}
                              onAmountChange={this.onAmountChange}
                              onDelete={this.onDeleteConfirm}
                            />
                          </Grid>
                        );
                      })
                }
              </Grid>
            </Box>
          </form >
        </DialogContent>
        <DialogActions>
          <Box mt={1} display="flex" alignItems="flex-end" justifyContent="space-between" style={{ gap: '12px' }}>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Typography
                variant="h6"
                style={{
                  color: paymentState === 'paid' ? 'green' : 'red',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '6px',
                }}
              >
                {paymentState === 'notenoughpaid'
                  ? <WarningIcon />
                  : paymentState === 'toomuchpaid'
                    ? <ErrorIcon />
                    : <ThumbUpIcon />
                }
                {`${formatAmount(paymentsTotal)} / ${formatAmount(orderTotal)}`}
              </Typography>
              <Typography
                variant="caption"
                style={{
                  marginTop: '-8px',
                }}
              >
                {
                  paymentState === 'toomuchpaid'
                    ? `Attention, l'élève a trop payé (${formatAmount(paymentsTotal - orderTotal)})`
                    : paymentState === 'paid'
                      ? 'payé'
                      : `Reste ${formatAmount(orderTotal - paymentsTotal)}`
                }
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" style={{ gap: '10px' }}>
              <Fade in={rows.length > 0}>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    const valid = document.getElementById('payments-form').reportValidity();
                    if (valid) {
                      const howMany = parseInt(prompt('Combien de fois souhaitez vous dupliquer le dernier paiement ? (entrez un chiffre)'), 10);
                      if (isNaN(howMany)) {
                        return;
                      }
                      const lastRow = this.getFormData().at(-1);
                      const newRows = Array(howMany).fill(true).map((_, index) => {
                        return {
                          amount: lastRow.amount ?? 0,
                          comment: lastRow.comment ?? '',
                          date: format(addMonths(new Date(lastRow.date), index + 1), 'yyyy-MM-dd'),
                          bank_id: lastRow.bank_id ?? null,
                          paymentType: lastRow.paymentType ?? '',
                          reference: lastRow.reference ?? '',
                          id: '',
                          valid: true,
                        };
                      });
                      this.setState((state) => {
                        const rows = [
                          ...this.getFormData(),
                          ...newRows,
                        ];
                        return {
                          rows,
                          paymentsTotal: rows.reduce((p, c) => p + parseFloat(c.amount), 0),
                        };
                      });
                    }
                  }}>
                  Ajouter N paiement(s)
                </Button>
              </Fade>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => {
                  const valid = document.getElementById('payments-form').reportValidity();
                  if (valid) {
                    const rows = this.getFormData();
                    const rowsLen = rows.length;
                    const newRow = {
                      amount: '',
                      comment: rowsLen > 0 ? rows.at(rowsLen - 1).comment : '',
                      date: format(rowsLen > 0 ? addMonths(new Date(rows.at(rowsLen - 1).date), 1) : new Date(), 'yyyy-MM-dd'),
                      bank_id: rowsLen > 0 ? rows.at(rowsLen - 1).bank_id : '',
                      paymentType: rowsLen > 0 ? rows.at(rowsLen - 1).paymentType : '',
                      reference: rowsLen > 0 ? rows.at(rowsLen - 1).reference : '',
                      id: '',
                      valid: true,
                    };
                    this.setState({
                      rows: [
                        ...rows,
                        newRow,
                      ],
                    });
                  }
                }}>
                Ajouter un paiement
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                type="submit"
                disabled={rows.length === 0}
                form="payments-form"
              >
                Enregistrer les paiements
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={close}
              >
                Retour
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Fragment>
    );
  }
}

OrdersPayments.propTypes = {
  close: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};

export default withStyles((theme) => ({
  ...tableStyles(theme),
}))(
  OrdersPayments
);
