import { FormControlLabel, Switch, Typography } from '@material-ui/core';

type Props = {
  timetable: any;
  onChange: (params: { checked: boolean; timetable: any }) => void;
}

export default function InPersonSwitch({
  timetable,
  onChange,
}: Props) {
  return (
    <FormControlLabel
      label={timetable.in_person
        ? (<Typography variant="caption" style={{}}>Présentiel</Typography>)
        : (<Typography variant="caption" style={{ textDecoration: 'line-through', opacity: .5 }}>Présentiel</Typography>)
      }
      control={
        <Switch
          size="small"
          checked={timetable.in_person === 1}
          onChange={(event, checked) => {
            onChange({
              checked,
              timetable,
            });
          }}
        />
      }
    />
  );
};
